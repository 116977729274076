<template>
  <tunnel-body>
    <h1 class="border-white-rock border-0 border-b border-solid mb-6 text-xl font-extrabold text-center pb-6">
      Paiement
    </h1>

    <p class="mb-10 text-center">
      👍&nbsp;Votre premier paiement est prévu la veille de votre jour de livraison.
    </p>

    <div class="max-w-1920 w-full mx-auto">
      <section class="pb-6 mb-6 border-solid border-0 border-b border-white-rock">
        <h2 class="font-extrabold text-base bp375:text-sm.5 mb-3 bp640:block">
          Cartes bancaires
          <payment-methods-icons-list
            :types="[PaymentMethodType.BankCard]"
            class="ml-2 bp640:block bp640:ml-0" />
        </h2>

        <tunnel-payment-message
          ref="TunnelPrimaryPaymentMessage"
          :is-blue-card-form-fill="isBankCardFormFill"
          :payment-methods="paymentMethods"
          message-type="primary"
          @onWarning="onPrimaryPreCheckError" />

        <payment-processing v-if="order" />

        <template v-else-if="!showBankCardForm">
          <payment-methods-selectable-item
            v-for="bankCard in getBankCards"
            :key="bankCard.id"
            :is-disabled="$wait.is(getPaymentMethodByCard(bankCard).loaders.editSettings) || paymentMethods.primary?.payment_id === bankCard.id"
            :is-loading="$wait.is(getPaymentMethodByCard(bankCard).loaders.editSettingsByCardId(bankCard.id))"
            :model-value="paymentMethods.primary?.payment_id === bankCard.id"
            :payment="{
              payment_type: 'blueCard',
              payment_id: bankCard.id
            }"
            class="mb-4"
            @update:modelValue="(value) => getPaymentMethodByCard(bankCard).actions.setDefault({cardId: bankCard.id, isDefault: value})">
            <template #type>
              Carte bancaire
            </template>
            <template #icon>
              <component :is="getCardIcon(bankCard)" />
            </template>
            <template #details>
              <template v-if="getPaymentMethodByCard(bankCard).integration === PaymentMethodIntegration.Stripe">
                {{ capitalize(bankCard.brand) }}
              </template>

              {{ bankCard.number.replace(/XXXX/g, '•••• ') }}
            </template>
          </payment-methods-selectable-item>

          <potager-button
            size="small"
            theme="stroke"
            @onClick="openModalPaymentMethodsHub">
            Ajouter une carte bancaire
          </potager-button>
        </template>

        <template v-if="!isAddingNewCardModal">
          <!-- use v-show otherwise forms are unmount before the @success event, because cards list are updated before -->
          <paygreen-new-card-form
            v-show="getPaymentGateway === PaymentMethodIntegration.Paygreen && showBankCardForm"
            ref="PaygreenNewCardForm"
            :name="PaymentMethods.PaygreenBankCard.name"
            class="mt-4"
            context="tunnel"
            hide-submit
            set-primary
            @canSubmit="(e) => isBankCardFormFill = e"
            @success="onSuccess" />

          <stripe-new-card-form
            v-show="(getPaymentGateway === PaymentMethodIntegration.Stripe || !getPaymentGateway) && showBankCardForm"
            ref="StripeNewCardForm"
            class="mt-4"
            context="tunnel"
            hide-submit
            set-primary
            @canSubmit="(e) => isBankCardFormFill = e"
            @success="onSuccess" />
        </template>

        <div
          v-else-if="showBankCardForm"
          class="mt-4 skeleton h-44 w-full" />
      </section>

      <section class="mb-8 bp768:mb-0">
        <h2 class="font-extrabold text-base bp375:text-sm.5 mb-3">
          Cartes Titres-restaurant
          <payment-methods-icons-list
            :types="[PaymentMethodType.RestaurantCard]"
            class="ml-2 bp640:block bp640:ml-0" />
        </h2>

        <tunnel-payment-message
          ref="TunnelSecondaryPaymentMessage"
          :is-blue-card-form-fill="isBankCardFormFill"
          :payment-methods="paymentMethods"
          message-type="secondary"
          @onWarning="onSecondaryPreCheckError" />

        <!-- EDENRED & SWILE -->
        <template v-for="PM in SecondaryLoginPaymentMethodsArray">
          <payment-methods-selectable-item
            v-if="getPaymentAccountByName(PM.name)"
            :key="PM.name"
            :is-loading="$wait.is(PM.loaders.editSettings)"
            :model-value="paymentMethods.secondary?.payment_type === PM.name"
            :payment="{ payment_type: PM.name }"
            class="mb-4"
            @update:modelValue="PM.actions.setDefault({isDefault: !(paymentMethods.secondary?.payment_type === PM.name)})">
            <template #type>
              <div class="first-letter:uppercase">
                {{ PM.label }}
              </div>
            </template>
            <template #icon>
              <component
                :is="icon"
                v-for="(icon, index) in PM.icons"
                :key="`${PM.name}-${index}`"
                :class="`text-${PM.name}`" />
            </template>
            <template #details>
              {{ getPaymentAccountByName(PM.name).email }}
            </template>
            <template #readmore>
              <a
                :href="PM.readmoreLink"
                :title="`En savoir plus sur ${PM.name}`"
                class="underline pt-4 block"
                target="_blank"
                @click.stop>
                En savoir plus
              </a>
            </template>
          </payment-methods-selectable-item>
        </template>

        <!-- Restaurants PM -->
        <template v-if="getRestaurantCards.length">
          <payment-methods-selectable-item
            v-for="card in getRestaurantCards"
            :key="card.id"
            :is-disabled="$wait.is(getPaymentMethodByCard(card).loaders.editSettings)"
            :is-loading="$wait.is(getPaymentMethodByCard(card).loaders?.editSettingsByCardId(card.id))"
            :model-value="card.id === paymentMethods.secondary?.payment_id"
            :payment="{ payment_type: PaymentMethodIntegration.Paygreen, payment_id: card.id }"
            class="mb-4 last:mb-0"
            @update:modelValue="(value) => getPaymentMethodByCard(card).actions.setDefault({cardId: card.id, isDefault: value})">
            <template #type>
              <div class="first-letter:uppercase">
                {{ getPaymentMethodByCard(card).label }}
              </div>
            </template>

            <template #icon>
              <component
                :is="icon"
                v-for="(icon, index) in getPaymentMethodByCard(card).icons"
                :key="`${card.issuer}-${index}`"
                :style="{ color: getPaymentMethodByCard(card).colors?.primary }" />
            </template>

            <template #details>
              {{ card.number.replace(/XXXX/g, '•••• ') }}
            </template>
          </payment-methods-selectable-item>
        </template>

        <secondary-payment-methods-list
          ref="SecondaryPaymentMethodsList"
          @close="isAddingNewCardModal = false"
          @onClick="openSecondaryPaymentMethodsList" />
      </section>

      <tunnel-payment-submit
        :is-disabled="showBankCardForm && !isBankCardFormFill"
        :is-loading="isPaymentLoading"
        class="text-center mb-8"
        @onSubmit="submitBankCardForm" />
    </div>
  </tunnel-body>
</template>

<script>
import { mapGetters } from 'vuex';
import { PAY_SUBSCRIPTION_BASKET } from 'Stores/types/subscriptionBasketActionsTypes';

import MetaInfosService from 'Classes/services/MetaInfoService';
import { ORDBASK022 } from 'Classes/error-handler/ErrorsMapper';
import { getUrlParameter } from 'Classes/utils/RouteUtils';
import {
  PaymentMethods,
  SecondaryLoginPaymentMethodsArray,
  getPaymentMethodByCard,
  getPaymentMethodByName,
  getCardIcon,
} from 'Classes/payment-methods';
import { PaymentMethodIntegration, PaymentMethodType } from 'potagerlogic/dist/Enums/PaymentMethods';
import { capitalize } from 'potagerlogic/dist/Formatting';

import PaymentMethodsMixin from 'Mixins/payments/PaymentMethodsMixin';
import GtmMixin from 'Mixins/GtmMixin';
import { PAYGREEN_PROCESSING_LOADER } from 'Mixins/payments/PaygreenMixin';

import TunnelBody from 'Components/tunnel/TunnelBody';
import TunnelPaymentMessage from 'Components/tunnel/TunnelPaymentMessage';
import TunnelPaymentSubmit from 'Components/tunnel/TunnelPaymentSubmit';
import PaymentMethodsIconsList from 'Components/paymentMethods/PaymentMethodsIconsList';
import PaymentMethodsSelectableItem from 'Components/paymentMethods/PaymentMethodsSelectableItem';
import StripeNewCardForm from 'Components/stripe/StripeNewCardForm';
import ModalInfo from 'Components/modals/ModalInfo';
import SecondaryPaymentMethodsList from 'Components/paymentMethods/SecondaryPaymentMethodsList';
import PaygreenNewCardForm from 'Components/paygreen/PaygreenNewCardForm';
import PaymentProcessing from 'Components/paymentMethods/PaymentProcessing';

import PotagerButton from 'UI/PotagerButton';

import { Context } from 'potagerlogic/dist/Enums/Context';

export default {
  mixins: [
    PaymentMethodsMixin,
    GtmMixin,
  ],

  components: {
    PaymentProcessing,
    PaygreenNewCardForm,
    SecondaryPaymentMethodsList,
    PotagerButton,
    TunnelBody,
    TunnelPaymentMessage,
    TunnelPaymentSubmit,
    PaymentMethodsIconsList,
    PaymentMethodsSelectableItem,
    StripeNewCardForm,
  },

  data: () => ({
    SecondaryLoginPaymentMethodsArray,
    PaymentMethods,
    PaymentMethodType,
    PaymentMethodIntegration,
    isAddingNewCardModal: false,
    isBankCardFormFill: false,
    order: null,
  }),

  computed: {
    ...mapGetters('tunnel', [
      'getNextStepRoute',
    ]),
    ...mapGetters('subscriptionBasket', [
      'getCoupons',
      'getPriceSubscription',
    ]),
    ...mapGetters('user', [
      'getRegionId',
      'getUserId',
      'getBankCards',
      'getRestaurantCards',
      'getPaymentAccountByName',
      'getPaymentGateway',
    ]),
    canSubmit() {
      return this.showBankCardForm ? this.isBankCardFormFill : this.paymentMethods.primary;
    },
    showBankCardForm() {
      // keep forms when PAYGREEN_PROCESSING_LOADER and only one card
      // else we have a issues with the paygreen new card form
      // where the form is unmount before the end of the process/listeners
      const processingAddCardModal = this.isAddingNewCardModal;
      const processingAddCardForm = this.$wait.is(PAYGREEN_PROCESSING_LOADER);
      return !processingAddCardModal && (processingAddCardForm || !this.getBankCards?.length);
    },
  },

  methods: {
    capitalize,
    getCardIcon,
    getPaymentMethodByCard,
    submitForm() {
      if (!this.paymentMethods.primary?.payment_id) {
        this.onPrimaryPreCheckError();
        return;
      }

      this.$store.dispatch(`subscriptionBasket/${PAY_SUBSCRIPTION_BASKET}`, this.paymentMethods.primary.payment_id)
        .then(({ data }) => {
          const {
            orders,
            customerSubscription
          } = data.data;
          this.order = orders[0];

          this.$nextTick(async () => {
            try {
              await this.doOrderRequiresAction(this.order, Context.SubscriptionBasket);
              this.trackSubscriptionTransaction(
                `${this.getUserId}-${this.getRegionId}`,
                customerSubscription,
                this.getCoupons,
                this.getPriceSubscription,
              );
            } finally {
              const cheaterWarning = data.warning?.find(w => w.code === ORDBASK022);
              if (cheaterWarning) this.$events.emit('error', cheaterWarning);

              const unmountPaygreen = this.$refs.PaygreenNewCardForm?.unmountPaygreen;
              if (unmountPaygreen) await unmountPaygreen();
              this.$router.push(this.getNextStepRoute());
            }
          });
        })
        .catch((err) => {
          console.error('PAY_SUBSCRIPTION_BASKET', err);
          this.$modal.open(ModalInfo, {
            title: 'Oops !',
            text: 'Allô Houspomme, une erreur est survenue. Veuillez réessayer ultérieurement ou contacter le support.',
          });
        });
    },
    submitBankCardForm() {
      if (this.showBankCardForm) {
        if (this.getPaymentGateway === PaymentMethodIntegration.Paygreen) {
          this.$refs.PaygreenNewCardForm.paygreenPayment();
        } else {
          this.$refs.StripeNewCardForm.stripePayment();
        }
      } else {
        this.submitForm();
      }
    },
    onSuccess(e) {
      this.paymentMethods.primary.payment_id = e.id;

      this.$nextTick(() => {
        this.submitForm();
      });
    },
    onPrimaryPreCheckError() {
      if (this.$refs?.TunnelPrimaryPaymentMessage) {
        this.$scrollTo(this.$refs.TunnelPrimaryPaymentMessage?.$el, 500);
      }
    },
    onSecondaryPreCheckError() {
      if (this.$refs?.TunnelSecondaryPaymentMessage) {
        this.$scrollTo(this.$refs.TunnelSecondaryPaymentMessage?.$el, 500);
      }
    },
    beforeOpenModal() {
      return new Promise((resolve) => {
        if (this.$refs.PaygreenNewCardForm) {
          this.$refs.PaygreenNewCardForm.unmountPaygreen()
            .then(() => {
              this.isAddingNewCardModal = true;
              resolve();
            });
        } else {
          resolve();
        }
      });
    },
    openModalPaymentMethodsHub() {
      this.beforeOpenModal()
        .then(() => {
          this.isAddingNewCardModal = true;

          this.trackAction({
            event: 'Modal',
            name: 'ModalTR',
            value: 'open',
          });

          const PMName = this.getPaymentGateway === PaymentMethodIntegration.Paygreen
            ? PaymentMethods.PaygreenBankCard.name
            : PaymentMethods.Stripe.name;

          getPaymentMethodByName(PMName).actions.openAddModal(this, undefined, () => {
            this.isAddingNewCardModal = false;
          });
        });
    },
    openSecondaryPaymentMethodsList(PM) {
      this.beforeOpenModal()
        .then(() => {
          this.$refs.SecondaryPaymentMethodsList.open(PM);
        });
    },
  },

  mounted() {
    if (getUrlParameter('from') === 'edenred') {
      this.trackAction({
        event: 'Modal',
        name: 'ModalPedagogyEdenred',
        value: 'success',
      });
    } else if (getUrlParameter('from') === 'swile') {
      this.trackAction({
        event: 'Modal',
        name: 'ModalPedagogySwile',
        value: 'success',
      });
    }

    this.$events.on('request:submitForm', this.submitBankCardForm);

    this.$events.on('paygreen:unmount', () => {
      if (this.isAddingNewCardModal) {
        this.isAddingNewCardModal = false;
      }
    });
  },

  beforeUnmount() {
    this.$events.off('request:submitForm', this.submitBankCardForm);
  },

  head: MetaInfosService.generate({
    title: 'J\'ajoute un moyen de paiement',
  }),
};
</script>
