<template>
  <panel
    :back-route="backRoute"
    title="Moyens de paiement">
    <div
      v-for="pmSection in paymentMethods"
      :key="pmSection.type"
      class="panel-section">
      <div
        v-if="pmSection.title && pmSection.pm.length > 1"
        class="panel-section__title">
        {{ pmSection.title }}
      </div>

      <panel-section-navigator
        v-for="PM in pmSection.pm"
        :key="PM.name"
        v-gtm
        :to="PM.route"
        class="items-center">
        <template #left>
          <span class="relative">
            <component
              :is="PM.defaultIcon"
              :style="{ color: PM.colors?.primary }" />

            <potager-badge
              v-if="getPaymentMethodCount(PM) > 0"
              class="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/3"
              rounded
              size="mini"
              theme="british-racing">
              {{ getPaymentMethodCount(PM) }}
            </potager-badge>
          </span>
        </template>

        <div class="mr-1 first-letter:uppercase inline-block">
          {{ PM.label }}
        </div>

        <div
          v-if="PM.description"
          class="text-xs.5 text-gray-500">
          {{ PM.description }}
        </div>
      </panel-section-navigator>
    </div>

    <div class="panel-section">
      <panel-section-navigator
        v-gtm
        :to="{ name: 'mon-compte_luncheons' }">
        Titres restaurant
        <potager-badge
          v-if="getCouponsLuncheon.length"
          size="small">
          {{ getCouponsLuncheon.length }}
        </potager-badge>
      </panel-section-navigator>
    </div>
  </panel>
</template>

<script>
import { mapGetters } from 'vuex';

import Panel from 'Components/panel/Panel';
import PanelSectionNavigator from 'Components/panel/section/PanelSectionNavigator';

import PotagerBadge from 'UI/PotagerBadge';

import MetaInfosService from 'Classes/services/MetaInfoService';
import { PrimaryPaymentMethodsArray, SecondaryPaymentMethodsArray } from 'Classes/payment-methods';
import { PaymentMethodIntegration, PaymentMethodType } from 'potagerlogic/dist/Enums/PaymentMethods';
import { Context } from 'potagerlogic/dist/Enums/Context';

export default {
  components: {
    PanelSectionNavigator,
    PotagerBadge,
    Panel,
  },

  props: {
    context: {
      type: String,
      default: Context.User,
    },
  },

  computed: {
    ...mapGetters('user', [
      'getPaymentAccountByName',
      'getBankCards',
      'getAllCards',
      'getPaymentGateway',
      'getCouponsLuncheon',
    ]),
    backRoute() {
      if (this.$potagerRoute.query.back) return JSON.parse(decodeURIComponent(this.$potagerRoute.query.back));
      switch (this.$potagerRoute.query.context) {
        case Context.Basket:
          return { name: 'basket_payment' };
        case Context.Order:
          const { orderId } = this.$potagerRoute.query;
          return orderId ? {
            name: 'mon-compte_orders_detail',
            params: { orderId }
          } : { name: 'mon-compte_orders' };
        default:
          return { name: 'mon-compte' };
      }
    },
    primaryPM() {
      const currentIntegration = this.getPaymentGateway === PaymentMethodIntegration.Paygreen
        ? PaymentMethodIntegration.Paygreen
        : PaymentMethodIntegration.Stripe;
      return PrimaryPaymentMethodsArray
        .filter((PM) => PM.integration === currentIntegration);
    },
    paymentMethods() {
      return [
        {
          title: 'Cartes bancaires',
          type: 'primary',
          pm: this.primaryPM,
        },
        {
          title: 'Cartes restaurant',
          type: 'secondary',
          pm: SecondaryPaymentMethodsArray,
        }
      ];
    },
  },

  methods: {
    getPaymentMethodCount(pm) {
      // edenred and swile (restaurant payment accounts)
      if ([PaymentMethodIntegration.Swile, PaymentMethodIntegration.Edenred].includes(pm.integration)) {
        return this.getPaymentAccountByName(pm.name) ? 1 : 0;
      }
      // bank cards
      if (pm.type === PaymentMethodType.BankCard) {
        return this.getBankCards.length;
      }
      // restaurant payment cards
      return this.getAllCards.filter((card) => card.issuer === pm.issuer).length;
    },
  },

  head: MetaInfosService.generate({
    title: 'Moyens de paiements',
  }),
};
</script>
