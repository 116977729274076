<template>
  <panel
    :back-route="backRoute"
    is-bianca
    title="Cartes de paiement">
    <div class="panel-section">
      <div class="panel-section__line">
        <bank-cards-selection-list
          v-if="getBankCards.length > 0"
          :selected-card-id="cardId"
          @onChange="onChange" />

        <potager-button
          v-if="cardId"
          :to="{
            ...backRoute,
            params: { cardId },
          }"
          class="mt-4"
          full-width
          label="Choisir cette carte"
          theme="wild-willow" />
      </div>

      <panel-section-navigator
        :to="{ name: 'mon-compte_payment-methods_credit-card_add', query: { context, skipHub: true } }">
        Ajouter une carte de paiement
      </panel-section-navigator>
    </div>
  </panel>
</template>

<script>
import { mapGetters } from 'vuex';
import PotagerButton from 'UI/PotagerButton';

import Panel from 'Components/panel/Panel';
import BankCardsSelectionList from 'Components/bankCard/BankCardsSelectionList';
import PanelSectionNavigator from 'Components/panel/section/PanelSectionNavigator';
import { Context } from 'potagerlogic/dist/Enums/Context';

export default {

  components: {
    PotagerButton,
    PanelSectionNavigator,
    Panel,
    BankCardsSelectionList,
  },

  data: () => ({
    backRoute: { name: 'basket_payment' },
    cardId: null,
    context: Context.Basket,
  }),

  computed: {
    ...mapGetters('user', [
      'getBankCards',
    ]),
  },

  methods: {
    onChange(val) {
      this.cardId = val;
    },
  },

  mounted() {
    this.cardId = this.$potagerRoute?.params?.cardId;
  },

};
</script>
