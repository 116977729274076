<template>
  <div
    :class="[
      'potager-switch potager-input',
      `potager-switch--${size}`,
      {
        'potager-switch--active': modelValue,
        'potager-switch--disabled': isDisabled,
        'potager-switch--loading': isLoading,
      },
    ]">
    <div
      class="potager-switch__toggler flex items-center"
      @click.stop="onClick">
      <div class="potager-switch__toggler__state">
        <icon-ptcy-loader-spinner
          v-if="isLoading"
          :class="[
            size === 'small' ? 'text-xxs' : 'text-xl',
            'potager-switch__toggler__state__spinner',
          ]" />
      </div>
    </div>
    <label
      v-if="$slots.default"
      class="potager-switch__label"
      @click.stop="onLabelClick">
      <slot />
    </label>
  </div>
</template>

<script>
import IconPtcyLoaderSpinner from 'Icons/regular/IconPtcyLoaderSpinner';
import { propsBuilder } from 'UI/Tools';

export const props = {
  modelValue: {
    type: Boolean,
    required: false,
    default: true,
  },
  isDisabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false,
  },
  size: {
    type: String,
    required: false,
    default: 'default',
    values: ['default', 'small'],
  },
  disableLabelClick: {
    type: Boolean,
    required: false,
    default: false,
  },
};

export default {

  components: {
    IconPtcyLoaderSpinner,
  },

  props: propsBuilder(props),

  emits: ['update:modelValue'],

  methods: {
    onClick() {
      const {
        isDisabled,
        isLoading,
        modelValue
      } = this;
      if (!isDisabled && !isLoading) {
        this.$emit('update:modelValue', !modelValue);
      }
    },
    onLabelClick() {
      if (!this.disableLabelClick) this.onClick();
    },
  },
};
</script>
