<template>
  <panel-section-navigator
    :target="targetBlank ? '_blank' : null"
    :to="redirect"
    class="content-summary-item__navigator">
    <div class="content-summary-item__link">
      <potager-picture
        :src="item.image"
        :title="title"
        class="content-summary-item__img" />

      <div class="content-summary-item__text">
        <component :is="titleComponent">
          {{ title }}
        </component>
        <p
          v-if="subtitle"
          class="content-summary-item__subtitle">
          {{ subtitle }}
        </p>
        <preparation
          v-if="item.type === 'recipe'"
          :recipe="item" />
      </div>
    </div>
  </panel-section-navigator>
</template>

<script>
import { getContentLink } from 'Classes/utils/RouteUtils';
import Preparation from 'Components/recette/Preparation';
import PotagerPicture from 'UI/PotagerPicture';

import { h } from 'vue';
import PanelSectionNavigator from 'Components/panel/section/PanelSectionNavigator.vue';

export default {

  components: {
    PanelSectionNavigator,
    Preparation,
    PotagerPicture,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
    targetBlank: {
      type: Boolean,
      required: false,
      default: false,
    },
    titleTag: {
      type: String,
      required: false,
      default: 'h3',
    },
  },

  computed: {
    redirect() {
      return getContentLink(this.item, null, true);
    },
    titleComponent() {
      const { titleTag: tag } = this;
      return {
        render() {
          return h(tag, {
            class: 'content-summary-item__title',
          }, {
            default: () => this.$slots.default(),
          });
        },
      };
    },
    title() {
      const { item } = this;
      if (item.type === 'supplier') {
        if (item.title) return item.title;
        return `${item.firstname} ${item.lastname}`;
      }
      if (item.type === 'recipe') return item.title;
      if (item.type === 'supplier-product') return item.name;
      if (item.type === 'report') return item.title;
      return null;
    },
    subtitle() {
      const { item } = this;
      if (item.type === 'supplier') {
        if (item.city && item.region) return `Localisation : ${item.city} (${item.region})`;
        return null;
      }
      return null;
    },
  },

};
</script>
