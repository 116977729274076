import WORDINGS from 'potagerlogic/dist/Constants/Wordings';
import { getGddMinimumDateBrief } from 'Classes/utils/DlpUtils';
import { Context } from 'potagerlogic/dist/Enums/Context';

/**
 * Determines the appropriate error message based on the given context and delivery day object.
 *
 * @param {string} context - The context in which the function is called.
 * @param {Object} gdd - The delivery day object.
 * @param {Object} gdd.timeSlot - The time slot for the delivery day.
 * @param {boolean} gdd.isFullForSubscription - Indicates if the delivery day is full for subscriptions.
 * @param {boolean} gdd.isTooLate - Indicates if the delivery day is too late.
 * @param {boolean} gdd.isFull - Indicates if the delivery day is full.
 * @param {boolean} gdd.isNextDeliveryAvailable - Indicates if the next delivery is available.
 * @returns {string|null} The appropriate error message or null if no error.
 */
export const getGddError = (gdd, { context = Context.Basket } = {}) => {
  const isSubscriptionContext = context.indexOf(Context.Subscription) !== -1;
  if (!gdd) return null;
  if (!gdd.timeSlot) return getGddDeleteError(gdd, { context });
  if (isSubscriptionContext && gdd.isFullForSubscription) return getGddFullError(gdd, { context });
  if (gdd.isTooLate) return WORDINGS.GDD_TOO_LATE.DEFAULT;
  if (gdd.isFull) return getGddFullError(gdd, { context });
  if (!gdd.isNextDeliveryAvailable) return WORDINGS.GDD_NOT_AVAILABLE.DEFAULT;
  return null;
};
/**
 * Handles the case when the selected delivery day is deleted.
 *
 * @param {string} context - The context in which the function is called.
 * @param {Object} gdd - The delivery day object.
 * @returns {string} The appropriate error message.
 */
export const getGddDeleteError = (gdd, { context = Context.Basket } = {}) => {
  switch (context) {
    case Context.Basket:
      return `${WORDINGS.GDD_DELETE.CONTEXT_BASKET.replace('{minimumDateBrief}', getGddMinimumDateBrief(gdd))}`;
    default:
      return WORDINGS.GDD_DELETE.DEFAULT;
  }
};

/**
 * Handles the case when the selected delivery day is full.
 *
 * @param {string} context - The context in which the function is called.
 * @param {Object} gdd - The delivery day object.
 * @param {Object} gdd.timeSlot - The time slot for the delivery day.
 * @param {boolean} gdd.isFullForSubscription - Indicates if the delivery day is full for subscriptions.
 * @param {boolean} gdd.isFull - Indicates if the delivery day is full.
 * @param {Object} gdd.notFullTimeslot - The next available time slot if the current one is full.
 * @returns {string} The appropriate error message.
 */
export const getGddFullError = (gdd, { context = Context.Basket } = {}) => {
  switch (context) {
    case Context.Basket:
      return WORDINGS.GDD_FULL.CONTEXT_BASKET
        .replace('{minimumDateBrief}', getGddMinimumDateBrief(gdd));
    default:
      return WORDINGS.GDD_FULL.DEFAULT;
  }
};
