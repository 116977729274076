<template>
  <component
    :is="tag"
    :class="getClasses">
    <slot />
  </component>
</template>

<script>
import { capitalize } from 'potagerlogic/dist/Formatting';
import { propsBuilder } from 'UI/Tools';

const BREAKPOINTS = [
  'bp375',
  'bp425',
  'bp480',
  'bp640',
  'bp840',
  'bp768',
  'bp1024',
  'bp1280',
];

const BREAKPOINTSPROPS = (() => {
  return BREAKPOINTS
    .reduce((props, val) => {
      props[val] = {
        type: [String, Number],
        required: false,
        default: null,
      };
      props[`offset${capitalize(val)}`] = {
        type: [String, Number],
        required: false,
        default: null,
      };
      return props;
    }, {});
})();

export const props = {
  col: {
    type: [Number, String],
    required: false,
    default: 12,
  },
  offset: {
    type: [Number, String],
    required: false,
    default: null,
  },
  ...BREAKPOINTSPROPS,
  tag: {
    type: String,
    required: false,
    default: 'div',
  },
};

export default {
  name: 'PotagerCol', // Usefull for PotagerForm

  props: propsBuilder(props),

  computed: {
    getClasses() {
      const base = 'potager__col';
      const classes = [base];

      const sanitizeFloat = (val) => String(val)
        .replace('.', '-');

      if (this.col) classes.push(`${base}--${sanitizeFloat(this.col)}`);
      if (this.offset) classes.push(`${base}--offset--${sanitizeFloat(this.offset)}`);

      BREAKPOINTS
        .forEach((e) => {
          const breakpointNameCamelCase = e.replace(/-./g, (x) => x[1].toUpperCase());
          const offsetName = `offset${capitalize(e)}`;
          if (this[breakpointNameCamelCase]) classes.push(`${base}--${e}-${sanitizeFloat(this[breakpointNameCamelCase])}`);
          if (this[offsetName]) classes.push(`${base}--offset--${e}-${sanitizeFloat(this[offsetName])}`);
        });

      return classes;
    },
  },

};
</script>
