<template>
  <potager-tooltip
    class="basket-preview__products-list__item-wrapper"
    shadow
    spacing=".5rem"
    tag="li"
    width="10rem">
    <potager-link
      v-if="box"
      v-wave
      :style="{ backgroundImage }"
      :to="getRedirect"
      class="basket-preview__products-list__item">
      <potager-button
        v-if="!box.isAssociableToVouchers"
        class="basket-preview__products-list__item__delete"
        is-circle
        prevent
        size="small"
        @onClick="deleteGroup">
        <template #icon>
          <icon-close />
        </template>
      </potager-button>
      <div
        v-if="isLoading"
        class="basket-preview__products-list__item__loader">
        <icon-ptcy-loader-spinner />
      </div>
    </potager-link>

    <template #message>
      {{ box.name }}
      <p>
        <b>
          <template v-if="groupedBoxes.length > 1">
            {{ groupedBoxes.length }} x
          </template>
          {{ formatPrice(getPrice) }}
        </b>
      </p>
    </template>
  </potager-tooltip>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  ADD_BOX_BASKET_ACTION,
  REMOVE_BOX_BASKET_ACTION,
  EMPTY_BASKET_ACTION,
} from 'Stores/types/basketActionsTypes';

import { formatPrice } from 'potagerlogic/dist/Formatting';

import { calcSlug } from 'Classes/utils/RouteUtils';

import BasketActionMixin from 'Mixins/BasketActionMixin';

import IconClose from 'Icons/regular/IconClose';
import IconPtcyLoaderSpinner from 'Icons/regular/IconPtcyLoaderSpinner';

import PotagerTooltip from 'UI/PotagerTooltip';
import PotagerButton from 'UI/PotagerButton';

export default {

  mixins: [
    BasketActionMixin,
  ],

  components: {
    IconClose,
    IconPtcyLoaderSpinner,
    PotagerTooltip,
    PotagerButton,
  },

  props: {
    groupedBoxes: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters('basket', [
      'getPriceCategory',
      'isSubscription',
    ]),
    box() {
      return this.groupedBoxes[0] ? this.groupedBoxes[0] : null;
    },
    backgroundImage() {
      return this.box && this.box.image ? `url(${this.box.image.url})` : '';
    },
    isLoading() {
      return this.$wait.is([
        `${ADD_BOX_BASKET_ACTION}_${this.box.id}`,
        `${REMOVE_BOX_BASKET_ACTION}_${this.box.id}`,
        EMPTY_BASKET_ACTION,
      ]);
    },
    subPrice() {
      return this.box.price?.subscription;
    },
    publicPrice() {
      return this.box.price?.oneShot;
    },
    getPrice() {
      return this.isSubscription ? this.subPrice : this.publicPrice;
    },
    getRedirect() {
      return {
        name: 'detail-product-item',
        params: {
          slug: calcSlug(this.box.name),
          id: this.box.id,
          idRegion: this.box.region.id,
        },
      };
    },
  },

  methods: {
    formatPrice,
    deleteGroup() {
      if (!this.isLoading) {
        this.removeBoxToBasket(this.box);
      }
    },
  },

};
</script>
