<template>
  <potager-col
    class="subscriber-bar__preview--message"
    col="12">
    <p
      v-if="isOrderCanceledStatus(getSubscriptionOrder)"
      class="subscriber-bar__legend">
      <template v-if="getSubscriptionOrder?.isResumable">
        Vous avez changé d’avis ? Reprenez votre commande avant le&nbsp;<b>
          {{ getOrderResumableDate(getSubscriptionOrder) }}
        </b> !
      </template>
      <template v-else-if="getNextDeliveryDate">
        <template v-if="!getSubscriptionOrder?.groupDeliveryDay?.isTooLate">
          <!-- basically when order cancelled and subscription inactive for the next delivery date -->
          <!-- => we can't resume this order, BUT still can order for this date -->
          Vous avez jusqu’au&nbsp;<b>{{ getOrderResumableDate(getSubscriptionOrder) }}</b> pour passer une nouvelle
          commande afin d'être livré
          le&nbsp;<b>{{ tmsToFormat(getNextDeliveryDate, 'iii dd MMMM') }}</b>.
        </template>
        <template v-else>
          Il est maintenant trop tard pour être livré le&nbsp;<b>
            {{ tmsToFormat(getNextDeliveryDate, 'iii dd MMMM') }}
          </b>.
          Rendez-vous au prochain
          panier !
        </template>
      </template>
    </p>

    <p
      v-else-if="getSubscriptionOrder && !getSubscriptionOrder?.containsSubscriptionBox"
      class="subscriber-bar__legend">
      <template v-if="getSubscriptionOrder?.isEditable">
        Vous avez remplacé votre panier.
        Vous avez jusqu’au&nbsp;<b>{{ getOrderResumableDate(getSubscriptionOrder) }}</b> pour compléter votre commande !
      </template>
      <template v-else>
        Il est maintenant trop tard pour modifier votre commande
        du&nbsp;<b>{{ tmsToFormat(getNextDeliveryDate, 'iii dd MMMM') }}</b>.
      </template>
    </p>

    <p
      v-else-if="getSubscriptionOrder?.isEditable"
      class="subscriber-bar__legend">
      Vous avez jusqu’au&nbsp;<b>{{ getOrderResumableDate(getSubscriptionOrder) }}</b> pour compléter votre commande !
    </p>
  </potager-col>
</template>

<script>
import SubscriptionMixin from 'Mixins/SubscriptionMixin';

import { isOrderCanceledStatus } from 'potagerlogic/dist/Utils/Order/OrderStatus';

import { getOrderResumableDate } from 'potagerlogic/dist/Utils/Order/OrderDate';
import { tmsToFormat } from 'potagerlogic/dist/Utils/Dates/DateFormat';

import PotagerCol from 'UI/PotagerCol';

export default {
  components: { PotagerCol },
  methods: {
    tmsToFormat,
    getOrderResumableDate,
    isOrderCanceledStatus
  },

  mixins: [
    SubscriptionMixin,
  ],

  props: {
    routes: {
      type: Object,
      required: true,
    },
  },
};
</script>
