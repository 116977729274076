<template>
  <potager-card
    v-gtm="'Carte abonnement'"
    :class="{
      'card-product-selector bg-white rounded-lg h-full flex flex-col overflow-hidden': true,
      'cursor-pointer': !!selectedRef,
      'ring-offset-4 ring-4 ring-beryl-green': isRecommended,
    }"
    :information-label="topCardMessage"
    :modal-btn-callback="onSubmit"
    :modal-items="getComposition"
    :modal-title="modalTitle"
    details-label="Voir la composition de la<br>semaine en cours"
    modal-btn-label="Choisir ce panier"
    modal-btn-theme="go-green"
    @click="onClick">
    <template #before-content>
      <div class="w-full relative bg-white-rock h-32">
        <transition
          mode="in-out"
          name="fade">
          <potager-picture
            :key="getImage && getImage.url"
            :src="getImage"
            background
            class="absolute inset-0 z-0 h-full"
            placeholder />
        </transition>
      </div>
    </template>

    <p class="font-bold mb-2.5">
      {{ category.name }}
    </p>

    <div class="w-full flex justify-start items-center">
      <potager-input
        v-if="showVariants"
        :model-value="selectedRef"
        :options="getSelectOptions"
        class="mr-2"
        name="product"
        type="select"
        @update:modelValue="onSelectRef" />

      <div class="w-min text-forest-green flex items-center whitespace-nowrap mr-2">
        <div v-if="getPrice">
          <span class="text-lg font-extrabold">
            {{ formatPrice(getPrice) }}
          </span>
        </div>
      </div>

      <template
        v-if="!showVariants">
        <span class="font-semibold text-xs.5 text-charcoal mr-2">
          {{ getProductPackagingMinimalWeight(getProduct) }}
        </span>
      </template>
    </div>

    <template #after-content>
      <div class="px-4 mb-4">
        <potager-button
          :is-disabled="!selectedRef || isDisabled"
          :is-loading="isLoading"
          class="min-w-32 bp768:min-w-min mt-auto"
          full-width
          prevent
          theme="go-green"
          @onClick="onSubmit">
          Choisir ce panier
        </potager-button>
      </div>
    </template>
  </potager-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { UPDATE_SUBSCRIPTION_PRODUCT_ACTION } from 'Stores/types/userActionsTypes';
import { UPDATE_SUBSCRIPTION_BASKET_CUSTOMER_SUBSCRIPTION_ACTION } from 'Stores/types/subscriptionBasketActionsTypes';

import { formatPrice } from 'potagerlogic/dist/Formatting';
import { getProductPackagingMinimalWeight } from 'potagerlogic/dist/Utils/Box/ProductPackaging';

import PotagerPicture from 'UI/PotagerPicture';
import PotagerButton from 'UI/PotagerButton';
import PotagerCard from 'UI/PotagerCard';
import PotagerInput from 'UI/Form/PotagerInput';

export default {
  components: {
    PotagerCard,
    PotagerPicture,
    PotagerInput,
    PotagerButton,
  },

  data: () => ({
    categorySelectedId: -1,
  }),

  props: {
    category: {
      type: Object,
      required: true,
    },
    selectedRef: {
      type: String,
      required: false,
      default: undefined,
    },
  },

  computed: {
    ...mapGetters('user', [
      'getSubscription',
    ]),
    ...mapGetters('session', [
      'getOacRecommendedRef',
    ]),
    getProduct() {
      return this.category.variants.find((v) => v.product.productReference === this.selectedRef)?.product;
    },
    getComposition() {
      return this.getProduct?.products;
    },
    getImage() {
      return this.getProduct?.image || this.category.variants[0].product.image;
    },
    getVariants() {
      return [...this.category.variants].sort((a, b) => {
        const wa = parseFloat(a.product.minWeight, 10);
        const wb = parseFloat(b.product.minWeight, 10);
        if (wa < wb) return -1;
        if (wa > wb) return 1;
        return 0;
      });
    },
    showVariants() {
      return this.getVariants.length > 1 || !this.getProduct;
    },
    getSelectOptions() {
      return [
        {
          value: null,
          label: 'Sélectionnez une taille',
          disabled: true,
        },
        ...this.getVariants.map((e) => ({
          value: e.product.productReference,
          label: `${e.format} - ${getProductPackagingMinimalWeight(e.product)}`,
        })),
      ];
    },
    getPrice() {
      return this.getProduct?.price?.subscription;
    },
    isRecommended() {
      return this.getOacRecommendedRef && this.getOacRecommendedRef === this.selectedRef;
    },
    topCardMessage() {
      switch (this.category.ref) {
        case 'CAT_PTMEL':
          return 'Le préféré des clients';
        case 'CAT_FRU':
          return 'Pour garder la pêche';
        case 'CAT_PLEG':
          return 'Pour les fan(ne)s de légumes';
        case 'CAT_MELBIO':
          return 'Le 100% bio';
        case 'CAT_SPMEL':
          return 'Des produits d’exception';
        default:
          return undefined;
      }
    },
    isLoading() {
      const id = this.getProduct?.id;
      return this.$wait.is([
        `${UPDATE_SUBSCRIPTION_PRODUCT_ACTION}_${id}`,
        `${UPDATE_SUBSCRIPTION_BASKET_CUSTOMER_SUBSCRIPTION_ACTION}_${id}`,
      ]);
    },
    isDisabled() {
      return this.$wait.is([
        `${UPDATE_SUBSCRIPTION_PRODUCT_ACTION}`,
        `${UPDATE_SUBSCRIPTION_BASKET_CUSTOMER_SUBSCRIPTION_ACTION}`,
      ]);
    },
    modalTitle() {
      const boxName = this.category.name?.toLowerCase();
      const boxVariant = this.getVariants.length > 1 ? this.getVariants.find((v) => v.product.productReference === this.selectedRef)
        ?.format
        ?.toLowerCase() : '';
      const boxVariantString = boxVariant ? `(format&nbsp;${boxVariant})` : '';
      const boxTitle = `${boxName} ${boxVariantString}`;
      return `<span class="font-normal">Cette semaine, retrouvez dans le </span><br>${boxTitle}`;
    },
  },

  methods: {
    formatPrice,
    getProductPackagingMinimalWeight,
    onSelectRef(e) {
      this.$emit('onSelectRef', e);
    },
    onSubmit() {
      this.$emit('onSubmit', this.getProduct);
    },
    onClick() {
      if (this.selectedRef) this.$events.emit('request:tunnelBoxOpenComposition');
    },
  },
};
</script>
