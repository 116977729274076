import {
  ADD_BOX_BASKET_ACTION,
  REMOVE_BOX_BASKET_ACTION,
  EDIT_BOX_QUANTITY_BASKET_ACTION,
  EMPTY_BASKET_ACTION,
  SET_BASKET_EXPIRATION_ACKNOWLEDGE_ACTION,
  UPDATE_BASKET_ACTION,
  UPDATE_DELIVERY_POINT_BASKET_ACTION
} from 'Stores/types/basketActionsTypes';

import {
  ADD_COUPON_ON_BASKET_ACTION,
  ADD_COUPON_ON_SUBSCRIPTION_BASKET_ACTION,
  ADD_PAYGREEN_CARD_ACTION,
  ADD_SUBSCRIPTION_BREAK_ACTION,
  AUTHORIZE_ORDER,
  DELETE_SUBSCRIPTION_BREAK_ACTION,
  EDIT_EDENRED_SETTINGS,
  EDIT_SWILE_SETTINGS,
  FINALIZE_STRIPE_SETUP_INTENT,
  GET_EDENRED_LOGIN_URL,
  GET_STRIPE_SECRET_CLIENT,
  GET_SUBSCRIPTION_TIME_SLOTS_ACTION,
  GET_SWILE_LOGIN_URL,
  INIT_PAYGREEN_BUYER,
  LOGOUT_EDENRED,
  LOGOUT_SWILE,
  PAY_BASKET_BY_PAYMENT_METHODS_ACTION,
  PAY_FREE_BASKET_ACTION,
  REMOVE_CARD_ACTION,
  REMOVE_COUPON_ON_BASKET_ACTION,
  REMOVE_COUPON_ON_SUBSCRIPTION_BASKET_ACTION,
  SET_DEFAULT_CARD_ACTION, UPDATE_DELIVERY_POINT_ORDER_ACTION, UPDATE_DELIVERY_POINT_SUBCRIPTION_ACTION,
  UPDATE_ORDER_3DS_STATUS
} from 'Stores/types/userActionsTypes';

import {
  CLEAR_SUBSCRIPTION_BASKET_ACTION,
  CONFIRM_SUBSCRIPTION_BASKET_ACTION,
  FETCH_PRODUCT_SELECTORS_ACTION,
  FETCH_PRODUCT_SELECTORS_V2_ACTION,
  PAY_SUBSCRIPTION_BASKET,
  UPDATE_SUBSCRIPTION_BASKET_CUSTOMER_SUBSCRIPTION_ACTION,
  UPDATE_SUBSCRIPTION_BASKET_DELIVERY_POINT_ACTION
} from 'Stores/types/subscriptionBasketActionsTypes';

import {
  FETCH_DELIVERY_POINT, FETCH_PUBLIC_DELIVERY_POINTS,
  LOCATE_USER,
  SEARCH_PRIVATES_DELIVERY_POINTS,
  SEARCH_PUBLICS_DELIVERY_POINTS,
  SEARCH_WOOS_DELIVERY_POINTS
} from 'Stores/types/mapActionsTypes';

import { SET_WOOS_MAP_LOADER } from 'Components/deliveryPoint/map/DlpMapPublic';

// Basket
export const BASKET_LOADERS = [
  UPDATE_BASKET_ACTION,
  ADD_BOX_BASKET_ACTION,
  REMOVE_BOX_BASKET_ACTION,
  EDIT_BOX_QUANTITY_BASKET_ACTION,
  UPDATE_DELIVERY_POINT_BASKET_ACTION,
  SET_BASKET_EXPIRATION_ACKNOWLEDGE_ACTION,
  EMPTY_BASKET_ACTION,
  ADD_COUPON_ON_BASKET_ACTION,
  REMOVE_COUPON_ON_BASKET_ACTION,
];

export const SUBSCRIPTION_BASKET_LOADERS = [
  UPDATE_SUBSCRIPTION_BASKET_DELIVERY_POINT_ACTION,
  UPDATE_SUBSCRIPTION_BASKET_CUSTOMER_SUBSCRIPTION_ACTION,
  CONFIRM_SUBSCRIPTION_BASKET_ACTION,
  ADD_COUPON_ON_SUBSCRIPTION_BASKET_ACTION,
  REMOVE_COUPON_ON_SUBSCRIPTION_BASKET_ACTION,
  PAY_SUBSCRIPTION_BASKET,
  FETCH_PRODUCT_SELECTORS_ACTION,
  FETCH_PRODUCT_SELECTORS_V2_ACTION,
  CLEAR_SUBSCRIPTION_BASKET_ACTION,
];

// Payments
export const STRIPE_LOADERS = [
  GET_STRIPE_SECRET_CLIENT,
  FINALIZE_STRIPE_SETUP_INTENT,
];

export const PAYGREEN_LOADERS = [
  INIT_PAYGREEN_BUYER,
  ADD_PAYGREEN_CARD_ACTION,
];

export const EDENRED_LOADERS = [
  GET_EDENRED_LOGIN_URL,
  EDIT_EDENRED_SETTINGS,
  LOGOUT_EDENRED,
];

export const SWILE_LOADERS = [
  GET_SWILE_LOGIN_URL,
  EDIT_SWILE_SETTINGS,
  LOGOUT_SWILE,
];

export const VARIOUS_PAYMENT_LOADERS = [
  SET_DEFAULT_CARD_ACTION,
  AUTHORIZE_ORDER,
  UPDATE_ORDER_3DS_STATUS,
  REMOVE_CARD_ACTION,
  PAY_BASKET_BY_PAYMENT_METHODS_ACTION,
  PAY_FREE_BASKET_ACTION,
];

export const PAYMENT_LOADERS = [
  ...STRIPE_LOADERS,
  ...PAYGREEN_LOADERS,
  ...EDENRED_LOADERS,
  ...SWILE_LOADERS,
  ...VARIOUS_PAYMENT_LOADERS,
];

// Map
export const MAP_STORE_LOADERS = [
  SET_WOOS_MAP_LOADER,
  SEARCH_WOOS_DELIVERY_POINTS,
  SEARCH_PUBLICS_DELIVERY_POINTS,
  SEARCH_PRIVATES_DELIVERY_POINTS,
  LOCATE_USER,
  FETCH_DELIVERY_POINT,
  FETCH_PUBLIC_DELIVERY_POINTS,
];

export const UPDATE_DELIVERY_POINT_LOADERS = [
  UPDATE_DELIVERY_POINT_BASKET_ACTION,
  UPDATE_DELIVERY_POINT_ORDER_ACTION,
  UPDATE_DELIVERY_POINT_SUBCRIPTION_ACTION,
  CONFIRM_SUBSCRIPTION_BASKET_ACTION,
];

export const SUBSCRIPTION_BREAK_LOADERS = [
  GET_SUBSCRIPTION_TIME_SLOTS_ACTION,
  ADD_SUBSCRIPTION_BREAK_ACTION,
  DELETE_SUBSCRIPTION_BREAK_ACTION
];
