<template>
  <panel
    :is-loading="!gazette"
    back-btn
    title="La gazette">
    <template v-if="gazette">
      <div class="panel-section">
        <p class="panel-section__p">
          Tout ce que vous devez savoir pour profiter des produits de votre commande. <br><b>
            Bonne
            dégustation&nbsp;!
          </b>
        </p>

        <div class="panel-section__separator" />

        <p class="panel-section__title">
          Votre commande n°{{ gazette.order.number }}
        </p>

        <ul class="panel-section__line">
          <li
            v-for="box in gazette.boxes"
            :key="box.id"
            class="panel__body__list__item">
            {{ box.name }}
          </li>
        </ul>
      </div>

      <template v-if="contents.length">
        <div
          v-for="(content, i) in contents"
          :id="calcSlug(content.title)"
          :key="i"
          ref="content"
          class="panel-section">
          <p class="panel-section__title">
            {{ content.title }}
          </p>

          <p class="panel-section__title-legend">
            {{ content.baseline }}
          </p>

          <content-summary-item
            v-for="item in content.items"
            :key="`content-summary-item-${item.id}`"
            :item="item" />

          <panel-section-navigator
            v-if="content.button"
            :to="content.button.redirect">
            {{ content.button.label }}
          </panel-section-navigator>
        </div>
      </template>
      <div
        v-else
        class="panel-section">
        <p class="panel-section__p">
          La gazette n'est pas encore disponible pour cette commande.
          <br>
          <b>Revenez plus tard !</b>
        </p>
      </div>
    </template>
  </panel>
</template>

<script>
import { calcSlug } from 'Classes/utils/RouteUtils';

import Panel from 'Components/panel/Panel';
import ContentSummaryItem from 'Components/content/ContentSummaryItem';
import PanelSectionNavigator from 'Components/panel/section/PanelSectionNavigator';

export default {

  components: {
    PanelSectionNavigator,
    Panel,
    ContentSummaryItem,
  },

  data: () => ({
    gazette: null,
    closest: null,
  }),

  computed: {
    status() {
      return this.$potagerRoute.params.status;
    },
    orderId() {
      return this.$potagerRoute.params.orderId;
    },
    contents() {
      return [{
        title: 'Nos astuces',
        baseline: 'pour conserver la fraîcheur de vos produits',
        items: this.gazette.products,
        button: {
          label: 'Tous nos produits',
          redirect: { name: 'products-list' },
        },
        tab: 'Astuces',
      }, {
        title: 'Nos idées recettes',
        baseline: 'à cuisiner avec vos produits',
        items: this.gazette.recipes,
        button: {
          label: 'Toutes nos recettes',
          redirect: { name: 'recettes-list' },
        },
        tab: 'Recettes',
      }, {
        title: 'Les producteurs',
        baseline: 'qui fournissent certains produits de cette commande',
        items: this.gazette.suppliers,
        button: {
          label: 'Tous les producteurs',
          redirect: { name: 'suppliers-list' },
        },
        tab: 'Producteurs',
      }].filter((e) => e.items.length);
    },
  },

  methods: {
    calcSlug,
  },

  created() {
    this.$api.order.getGazette(this.$potagerRoute.params.orderId)
      .then(({ data }) => {
        this.gazette = data.data;
      });
  },

};
</script>
