<template>
  <tunnel-body
    :is-ready="isReady"
    size="wide">
    <potager-container class="max-w-6/12 text-center">
      <h1 class="text-xl font-extrabold mb-3 mx-10">
        Des options sont disponibles &nbsp;!&nbsp;🎉
      </h1>

      <p class="mb-10">
        Vous avez choisi le <b>{{ getCustomerSubscriptionProduct?.name }}</b>.
        Vous pouvez ajouter une option à ce panier&nbsp;! Sélectionnez le produit que vous aimeriez recevoir
        en plus dans votre panier chaque semaine.
      </p>

      <template v-if="isReady">
        <div class="mb-6">
          <potager-card-button
            v-for="option in category.options"
            :key="option.targetBoxId"
            :image="getOptionImage(option)"
            :is-bio="!!option.name.match(/bio/i)"
            :is-disabled="!option.available"
            :is-loading="isOptionLoading(option.targetBoxId)"
            :is-lock="isLoading"
            :is-selected="option.checked"
            is-selectable
            @onClick="fetchBoxOptions(option.targetBoxId)">
            <div class="h-full w-full text-left">
              {{ option.name }}

              <p
                v-if="option.available"
                class="text-forest-green">
                <span class="text-lg font-extrabold inline-block">
                  + {{ formatPrice(getOptionPriceDelta(option)) }}
                </span>
              </p>

              <p
                v-else
                class="mb-1 pt-1 bp768:text-xs.5">
                Vous ne pouvez choisir qu’un supplément.
              </p>
            </div>
          </potager-card-button>
        </div>

        <div class="text-center">
          <potager-button
            :is-loading="isSubmitLoading"
            class="potager-button--tunnel"
            theme="go-green"
            @onClick="submitNewSubscription(category.box)">
            {{ hasOption ? 'Continuer' : 'Continuer sans supplément' }}
          </potager-button>
        </div>
      </template>
    </potager-container>
  </tunnel-body>
</template>

<script>
import { mapGetters } from 'vuex';
import { UPDATE_SUBSCRIPTION_PRODUCT_ACTION } from 'Stores/types/userActionsTypes';
import { UPDATE_SUBSCRIPTION_BASKET_CUSTOMER_SUBSCRIPTION_ACTION } from 'Stores/types/subscriptionBasketActionsTypes';

import TunnelSubscriptionMixin from 'Mixins/TunnelSubscriptionMixin';
import GtmMixin from 'Mixins/GtmMixin';

import TunnelBody from 'Components/tunnel/TunnelBody';

import PotagerContainer from 'UI/PotagerContainer';
import PotagerButton from 'UI/PotagerButton';
import PotagerCardButton from 'UI/PotagerCardButton';

import { formatPrice } from 'potagerlogic/dist/Formatting';

export const FETCH_BOX_OPTIONS = 'FETCH_BOX_OPTIONS';

export default {

  mixins: [
    TunnelSubscriptionMixin,
    GtmMixin,
  ],

  components: {
    PotagerCardButton,
    TunnelBody,
    PotagerContainer,
    PotagerButton,
  },

  data: () => ({
    category: null,
  }),

  computed: {
    ...mapGetters('user', [
      'getSubscription',
    ]),
    ...mapGetters('subscriptionBasket', [
      'getCustomerSubscriptionProduct',
    ]),
    ...mapGetters('tunnel', [
      'getNextStepRoute',
    ]),
    isSubmitLoading() {
      return this.$wait.is([
        UPDATE_SUBSCRIPTION_PRODUCT_ACTION,
        UPDATE_SUBSCRIPTION_BASKET_CUSTOMER_SUBSCRIPTION_ACTION,
      ]);
    },
    isReady() {
      return !!this.category;
    },
    isLoading() {
      return this.$wait.is(FETCH_BOX_OPTIONS);
    },
    hasOption() {
      return this.category.options.some((option) => option.checked);
    },
  },

  watch: {
    getCustomerSubscriptionProduct: {
      handler(val, oldval) {
        if (val && (!oldval || val.id !== oldval.id)) this.fetchBoxOptions(val.id);
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    formatPrice,
    fetchBoxOptions(boxId) {
      return new Promise((resolve) => {
        this.$wait.start(FETCH_BOX_OPTIONS);
        this.$wait.start(`${FETCH_BOX_OPTIONS}_${boxId}`);

        const regionId = this.getCustomerSubscriptionProduct.region.id;
        const categoryId = this.getCustomerSubscriptionProduct.productSelectorId;
        this.$api.formule.findOneBySelector(regionId, categoryId, boxId)
          .then(({ data }) => {
            this.category = data;
            resolve(data);
          })
          .finally(() => {
            this.$wait.end(FETCH_BOX_OPTIONS);
            this.$wait.end(`${FETCH_BOX_OPTIONS}_${boxId}`);
          });
      });
    },
    getOptionPriceDelta(option) {
      return option?.priceDelta?.subscription;
    },
    getOptionImage(option) {
      if (option?.name && !this.$mq.bp375) {
        if (option.name.match(/fruits secs/i)) return 'box-options-fruits-secs';
        if (option.name.match(/oeufs/i)) return 'box-options-oeufs';
      }
      return null;
    },
    isOptionLoading(id) {
      return this.$wait.is(`${FETCH_BOX_OPTIONS}_${id}`);
    },
  },
};
</script>
