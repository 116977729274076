export default (to, from, next) => {
  if (to.meta.keepQuery) {
    const query = { ...from.query, ...to.query };

    // Si les queries sont identiques, ne pas rediriger pour éviter une infinite loop
    return JSON.stringify(to.query) === JSON.stringify(query)
      ? next()
      : next({
        ...to,
        query
      });
  }

  next();
};
