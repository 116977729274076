<template>
  <potager-link
    :to="{
      name: 'mon-compte_orders_gazette',
      params: {
        status: order.isHistorical ? 'livrees' : 'en-cours',
        orderId: order.id,
      }
    }"
    class="panel-section panel-section--slaying bg-white-rock p-6">
    <div class="flex items-center">
      <icon-chef-gear-hat class="mr-4 text-2xl" />

      <span class="flex flex-col">
        <span class="text-sm.5 font-semibold">
          Votre Gazette
        </span>

        <span class="text-xs">
          Nos <b class="text-semibold">conseils</b> et <b class="text-semibold">recettes</b><br>pour cuisiner votre commande&nbsp;!
        </span>
      </span>
    </div>

    <div class="absolute right-0 top-0 bottom-0 left-0 overflow-hidden">
      <potager-picture
        class="absolute right-0 top-0 account-order-gazette__plate-1"
        src="gazette-plate-1"
        width="72px" />
      <potager-picture
        class="absolute right-0 bottom-0 account-order-gazette__plate-2"
        src="gazette-plate-2"
        width="72px" />
    </div>
  </potager-link>
</template>

<script>
import PotagerPicture from 'UI/PotagerPicture';
import IconChefGearHat from 'Icons/regular/IconChefGearHat';

export default {
  name: 'AccountOrderGazette',
  components: {
    PotagerPicture,
    IconChefGearHat,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
};
</script>
