<template>
  <component
    :is="hasTooltip ? 'potager-tooltip' : getTag"
    v-wave="{ disabled: !ripple }"
    :aria-disabled="isDisabled"
    :aria-label="label || ariaLabel"
    :class="[...getClasses, buttonClass]"
    :disabled="isLoading || isDisabled"
    :message="disabledMessage || tooltip"
    :position="tooltipPosition"
    :tabindex="isDisabled ? -1 : tabindex"
    :tag="hasTooltip ? getTag : undefined"
    :target="isButton ? null : target"
    :to="to || href"
    :type="isButton ? type : null"
    theme="dark"
    @click="onClick">
    <slot name="content">
      <span
        v-if="$slots.icon || (isCircle && $slots.default)"
        class="potager-button__icon">
        <slot name="icon" />
        <slot v-if="!$slots.icon" />
      </span>

      <span
        v-if="($slots.default || label) && !isCircle"
        :class="[
          'potager-button__text w-full',
          { 'truncate': !multiLine }
        ]">
        <slot>
          {{ label }}
        </slot>
      </span>
    </slot>

    <slot name="right">
      <component
        :is="getLoader"
        v-if="isLoading"
        class="potager-button__loader" />
    </slot>
  </component>
</template>

<script>
import IconPtcyLoaderSpinner from 'Icons/regular/IconPtcyLoaderSpinner';
import IconPtcyLoaderDots from 'Icons/regular/IconPtcyLoaderDots';

import { propsBuilder } from 'UI/Tools';
import PotagerTooltip, { tooltipPositions } from 'UI/PotagerTooltip';

import { BUTTON_THEMES } from 'Classes/Constants';

export const props = {
  label: {
    type: [String, Number, Boolean],
    required: false,
    default: '',
  },
  ariaLabel: {
    type: [String, Number],
    required: false,
    default: undefined,
  },
  to: {
    type: [Object, String],
    required: false,
    default: undefined,
  },
  href: {
    type: String,
    required: false,
    default: undefined,
  },
  target: {
    type: String,
    required: false,
    default: undefined,
    values: ['_blank', '_parent', '_self', '_top'],
  },
  theme: {
    default: 'default',
    required: false,
    type: String,
    values: BUTTON_THEMES,
  },
  size: {
    type: String,
    required: false,
    default: 'default',
    values: ['small', 'default', 'mini'],
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false,
  },
  isDisabled: {
    type: [Boolean, null],
    required: false,
    default: null,
  },
  disabledMessage: {
    type: String,
    required: false,
    default: undefined,
  },
  isSquare: {
    type: Boolean,
    required: false,
    default: false,
  },
  isCircle: {
    type: Boolean,
    required: false,
    default: false,
  },
  isRounded: {
    type: Boolean,
    required: false,
    default: false,
  },
  fullWidth: {
    type: Boolean,
    required: false,
    default: false,
  },
  shadow: {
    type: Boolean,
    required: false,
    default: false,
  },
  prevent: {
    type: Boolean,
    required: false,
    default: false,
  },
  pulse: {
    type: Boolean,
    required: false,
    default: false,
  },
  ripple: {
    type: Boolean,
    required: false,
    default: true,
  },
  buttonClass: {
    type: [String, Array, Object],
    required: false,
    default: '',
  },
  tooltip: {
    type: String,
    required: false,
    default: undefined,
  },
  tooltipPosition: {
    type: String,
    required: false,
    default: undefined,
    values: tooltipPositions,
  },
  tabindex: {
    type: [String, Number],
    required: false,
    default: undefined,
  },
  type: {
    type: String,
    required: false,
    default: 'button',
    values: ['button', 'submit', 'reset'],
  },
  reset: {
    type: Boolean,
    required: false,
    default: false,
  },
  multiLine: {
    type: Boolean,
    required: false,
    default: false,
  },
  noPadding: {
    type: Boolean,
    required: false,
    default: false,
  },
};

export default {
  name: 'PotagerButton',

  props: propsBuilder(props),

  components: {
    PotagerTooltip,
  },

  emits: ['onClick'],

  computed: {
    hasTooltip() {
      return (this.isDisabled && this.disabledMessage) || this.tooltip;
    },
    isRouterLink() {
      return !!this.to && !this.isDisabled;
    },
    isLink() {
      return !!this.href && !this.isDisabled;
    },
    isButton() {
      return !(this.to || this.href);
    },
    isOutlined() {
      return this.theme.indexOf('stroke') !== -1;
    },
    getClasses() {
      const defaultClass = !this.reset && this.theme !== 'reset' ? `potager-button--${this.theme}` : '';
      return [
        defaultClass,
        `potager-button--size-${this.size}`,
        {
          'potager-button': !this.reset && this.theme !== 'reset',
          'potager-button--reset': this.reset || this.theme === 'reset',
          'potager-button--outlined': this.isOutlined,
          'potager-button--square': this.isSquare,
          'potager-button--circle': this.isCircle,
          'potager-button--rounded': this.isRounded,
          'potager-button--full-width': this.fullWidth,
          'potager-button--shadow': this.shadow,
          'potager-button--is-loading': this.isLoading,
          'potager-button--pulse': this.pulse,
          'potager-button--disabled': this.isDisabled,
          'cursor-wait': this.isLoading,
          'potager-button--no-padding': this.noPadding,
        },
      ];
    },
    getTag() {
      if (this.isRouterLink || this.isLink) return 'potager-link';
      return 'button';
    },
    getLoader() {
      return this.isSquare || this.isCircle ? IconPtcyLoaderSpinner : IconPtcyLoaderDots;
    },
  },

  methods: {
    onClick(e) {
      if (this.prevent) {
        e.preventDefault();
        e.stopPropagation();
      }
      this.$emit('onClick');

      if (this.to) {
        // auto-close the menu and scroll when needed, even if :to is the same as the current route
        this.$events.emit('scrollToHash');
      }
    },
  },

};
</script>

<style lang="scss" scoped>
.potager-button {
  &__element {
    @apply inline-flex items-center justify-center w-full h-full select-none;
  }
}
</style>
