<template>
  <transition name="slide-left-to-right-2rem">
    <potager-info
      v-if="getMessage"
      :class="[
        'px-6 py-4 rounded mb-7 bp768:text-xs.5',
        isWarning ? 'bg-warning text-white font-semibold' : 'bg-white-rock',
      ]"
      align="center">
      <template #icon>
        <component
          :is="getIcon"
          :class="[
            'mr-2',
            getMessageConditions === 'secondPaymentAdded' ? 'text-british-racing' : '',
          ]" />
      </template>

      <span v-html="getMessage" />
    </potager-info>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import PotagerInfo from 'UI/PotagerInfo';

import IconCalendarClock from 'Icons/regular/IconCalendarClock';
import IconCreditCard from 'Icons/bold/IconCreditCard';
import IconCheck from 'Icons/bold/IconCheck';

export default {

  components: {
    PotagerInfo,
  },

  props: {
    paymentMethods: {
      type: Object,
      required: false,
      default: () => {
      },
    },
    isBlueCardFormFill: {
      type: Boolean,
      required: false,
      default: true,
    },
    messageType: {
      type: String,
      required: false,
      default: undefined,
      validator: (val) => [undefined, 'primary', 'secondary'].includes(val),
    },
  },

  data: () => ({
    noWarning: true,
  }),

  computed: {
    ...mapGetters('user', [
      'getBankCards',
    ]),
    ...mapGetters('subscriptionBasket', [
      'getPriceSubscription',
    ]),
    getMessageConditions() {
      const {
        primary,
        secondary
      } = this.paymentMethods;

      // par defaut on présente comme valide le formulaire d'ajout de carte
      // pour ne pas afficher de message d'erreur au chargement de la page
      const forceNoWarning = this.noWarning;
      let decision = forceNoWarning ? 'firstOrderAnd' : '';

      const HavePrimaryDecisionTree = () => {
        decision = `${decision}HasPrimary`;

        if (secondary) {
          // et a un secondaire
          decision = `${decision}AndHasSecondary`;
        } else {
          // mais pas secondaire
          decision = `${decision}AndDoesntHaveSecondary`;
        }
      };

      const noPrimaryDecisionTree = () => {
        decision = `${decision}DoesntHavePrimary`;

        if (this.getBankCards?.length) {
          // mais possible d'en sélectionné un
          decision = `${decision}ButCanSelectOne`;

          if (secondary) {
            // et a un secondaire
            decision = `${decision}AndHasSecondary`;
          } else {
            // et n'a pas de secondaire
            decision = `${decision}AndDoesntHaveSecondary`;
          }
        } else {
          // et n'a pas de primaire pre-enregistré

          if (secondary) {
            // mais a un secondaire
            decision = `${decision}AndHasSecondary`;
          } else {
            // et n'a pas non plus de secondaire
            decision = `${decision}AndDoesntHaveSecondary`;
          }
        }

        if (parseFloat(this.getPriceSubscription?.total) === 0) {
          decision = `${decision}AndIsFree`;
        }
      };

      // Full decision Tree
      if (primary || this.isBlueCardFormFill) {
        // A un primaire
        HavePrimaryDecisionTree();
      } else {
        // N'a pas de primaire
        noPrimaryDecisionTree();
      }

      return decision;
    },
    getIcon() {
      if (this.getMessageConditions.includes('DoesntHavePrimary') && this.messageType !== 'secondary') {
        return IconCreditCard;
      }
      if (this.getMessageConditions.includes('HasSecondary') && !this.getMessageConditions.includes('HasPrimary')) {
        return IconCheck;
      }
      return IconCalendarClock;
    },
    getMessage() {
      const noneMessage = 'Veuillez ajouter une carte bancaire pour confirmer votre abonnement.';
      const secondaryMessage = 'Votre carte titre restaurant est enregistrée.';
      const secondaryNoPrimaryMessage = 'Afin de finaliser la création de votre abonnement, ajoutez une carte bancaire pour compléter '
        + 'le montant payé par carte titre restaurant si besoin.';
      const noPrimarySelectedMessage = 'Veuillez choisir un moyen de paiement pour confirmer votre abonnement.';

      switch (this.getMessageConditions) {
        case 'DoesntHavePrimaryButCanSelectOneAndHasSecondary':
        case 'DoesntHavePrimaryAndHasSecondary':
        case 'firstOrderAndDoesntHavePrimaryAndHasSecondary':
          return this.messageType === 'secondary' ? `<b class="text-british-racing">${secondaryMessage}</b><br>${secondaryNoPrimaryMessage}` : secondaryNoPrimaryMessage;
        case 'DoesntHavePrimaryAndDoesntHaveSecondary':
          return this.messageType !== 'secondary' ? noneMessage : undefined;
        case 'DoesntHavePrimaryButCanSelectOneAndDoesntHaveSecondary':
          return this.messageType !== 'secondary' ? noPrimarySelectedMessage : undefined;
        case 'HasPrimaryAndHasSecondary':
        default:
          if (this.getMessageConditions.includes('DoesntHavePrimary') && this.getMessageConditions.includes('AndIsFree') && this.messageType !== 'secondary') {
            return 'Pourquoi avons-nous besoin d\'enregistrer une CB ? Au cas ou vous souhaiteriez compléter votre commande avec des produits'
              + ' à l\'unité et/ou pour votre panier de la semaine prochaine ! Si votre commande reste d\'un montant de 0€, '
              + 'vous ne serez pas débité(e).';
          }
          return undefined;
      }
    },
    isPrimaryCardWarning() {
      return this.messageType !== 'secondary' && this.getMessageConditions.includes('DoesntHavePrimary') && !this.getMessageConditions.includes('firstOrder');
    },
    isSecondaryCardWarning() {
      return this.getMessageConditions.includes('HasPrimary') && this.getMessageConditions.includes('DoesntHaveSecondary');
    },
    isWarning() {
      return this.messageType !== 'secondary' ? this.isPrimaryCardWarning || this.isSecondaryCardWarning : this.isSecondaryCardWarning;
    },
  },
  watch: {
    isWarning(isWarning) {
      if (isWarning) {
        this.$emit('onWarning');
      }
    },
    getMessageConditions() {
      this.noWarning = false;
    },
    paymentMethods: {
      handler() {
        this.noWarning = true;
      },
      deep: true,
    },
  },
};
</script>
