import IconPtcyVisa from 'Icons/color/IconPtcyVisa';
import IconPtcyMastercard from 'Icons/color/IconPtcyMastercard';
import IconPtcyAmex from 'Icons/color/IconPtcyAmex';
import { PaymentMethodType } from 'potagerlogic/dist/Enums/PaymentMethods';
import { PaygreenBrand, StripeBrand } from 'potagerlogic/dist/Enums/PaymentCard';

import Stripe from 'Classes/payment-methods/Stripe';
import Edenred from 'Classes/payment-methods/Edenred';
import Swile from 'Classes/payment-methods/Swile';
import Up from 'Classes/payment-methods/Up';
import Pluxee from 'Classes/payment-methods/Pluxee';
import Bimpli from 'Classes/payment-methods/Bimpli';
import PaygreenBankCard from 'Classes/payment-methods/PaygreenBankCard';

export const PaymentMethods = {
  Stripe,
  Edenred,
  Swile,
  Up,
  Pluxee,
  Bimpli,
  PaygreenBankCard,
};

export const PrimaryPaymentMethodsArray = [PaymentMethods.Stripe, PaymentMethods.PaygreenBankCard];

export const SecondaryLoginPaymentMethodsArray = [PaymentMethods.Edenred, PaymentMethods.Swile];

export const SecondaryCardPaymentMethodsArray = [PaymentMethods.Bimpli, PaymentMethods.Pluxee, PaymentMethods.Up];

export const SecondaryPaymentMethodsArray = [...SecondaryLoginPaymentMethodsArray, ...SecondaryCardPaymentMethodsArray].sort((a, b) => a.name.localeCompare(b.name));

export const PaymentMethodsArray = [...PrimaryPaymentMethodsArray, ...SecondaryPaymentMethodsArray];

// Utils
/**
 * Retrieves the payment method by its name or alias.
 *
 * @param {string} name - The name or alias of the payment method.
 * @returns {Object|undefined} - The payment method object or undefined if not found.
 */
export const getPaymentMethodByName = (name) => PaymentMethodsArray
  .find((e) => e.name === name || e.alias === name);

/**
 * Retrieves the payment method based on the provided card details.
 *
 * @param {Object} card - The card object containing details about the card.
 * @param {string} card.brand - The brand of the card.
 * @param {string} card.issuer - The issuer of the card.
 * @returns {Object|undefined} - The payment method object or undefined if not found.
 */
export const getPaymentMethodByCard = (card) => {
  if (!card) return undefined;
  // issuer/brand is used for card
  // name is used for loginAccounts
  const {
    brand,
    issuer,
    name
  } = card;
  if (Object.values(StripeBrand)
    .includes(brand)) {
    return PaymentMethods.Stripe;
  }
  if (Object.values(PaygreenBrand)
    .includes(brand)) {
    return PaymentMethods.PaygreenBankCard;
  }
  return PaymentMethodsArray
    .find((method) => (issuer && method.issuer === issuer) || (name && method.name === name));
};

/**
 * Retrieves the icon for a given card.
 *
 * @param {Object} card - The card object containing details about the card.
 * @param {boolean} [alternative=false] - Flag to determine if the alternative icon should be returned.
 * @returns {Object|null} - The icon object for the card or null if not found.
 */
export const getCardIcon = (card, alternative = false) => {
  const paymentMethod = getPaymentMethodByCard(card);
  if (paymentMethod.type === PaymentMethodType.BankCard) {
    return {
      visa: IconPtcyVisa,
      mastercard: IconPtcyMastercard,
      amex: IconPtcyAmex,
    }[card.brand] || null;
  }

  return alternative ? paymentMethod.alternativeIcons[0] : paymentMethod.defaultIcon;
};
