<template>
  <panel
    :back-route="{ name: 'login' }"
    title="Réinitialiser votre mot de passe">
    <div class="panel-section">
      <p class="panel-section__title">
        Mot de passe oublié&nbsp;?
      </p>

      <p class="panel-section__p">
        Pas de panique ! Nous allons vous aider à générer un nouveau mot de passe
      </p>

      <potager-form
        id="forgot-password"
        :models="[emailPassword]"
        class="panel-section__line"
        @canSubmit="(e) => isDisabled = !e"
        @onSubmit="sendEmailPassword">
        <potager-input
          v-model="emailPassword"
          :constraints="[
            { constraint: 'required', field: 'emailPassword' },
            { constraint: 'email', field: 'emailPassword' },
          ]"
          label="Votre adresse email"
          name="emailPassword"
          type="email">
          <template #icon>
            <icon-email-action-unread />
          </template>
        </potager-input>

        <potager-button
          :is-disabled="isDisabled"
          :is-loading="isLoading"
          full-width
          theme="go-green"
          type="submit">
          Envoyer un email
        </potager-button>
      </potager-form>
    </div>
  </panel>
</template>

<script>
import { mapGetters } from 'vuex';
import { REQUEST_FORGOT_PASSWORD_EMAIL_ACTION, RESET_PASSWORD_ACTION } from 'Stores/types/sessionActionsTypes';

import PotagerButton from 'UI/PotagerButton';
import PotagerForm from 'UI/Form/PotagerForm';
import PotagerInput from 'UI/Form/PotagerInput';

import Panel from 'Components/panel/Panel';

import IconEmailActionUnread from 'Icons/regular/IconEmailActionUnread';

import MetaInfosService from 'Classes/services/MetaInfoService';
import ModalInfo from 'Modals/ModalInfo';

export default {

  components: {
    Panel,
    PotagerButton,
    PotagerForm,
    PotagerInput,
    IconEmailActionUnread,
  },

  data: () => ({
    emailPassword: '',
    isDisabled: false,
  }),

  computed: {
    ...mapGetters('temporaryCustomer', [
      'getTemporaryCustomerId',
    ]),
    isLoading() {
      return this.$wait.is([
        REQUEST_FORGOT_PASSWORD_EMAIL_ACTION,
        RESET_PASSWORD_ACTION,
      ]);
    },
  },

  methods: {
    sendEmailPassword() {
      this.$store.dispatch(`session/${REQUEST_FORGOT_PASSWORD_EMAIL_ACTION}`, {
        emailPassword: this.emailPassword,
        temporaryCustomerId: this.getTemporaryCustomerId,
        from: null,
        resetPasswordUrl: null,
      })
        .then(() => {
          this.$modal.open(ModalInfo, {
            title: 'Consultez vos emails !',
            text: 'Pour vérifier votre identité, nous vous avons envoyé un email où vous pourrez définir un nouveau mot de passe. Cet email arrive parfois dans les spams, vérifiez bien votre dossier de spams si vous ne l’apercevez pas dans votre boite de réception.',
          });
        });
    },
  },

  head() {
    return MetaInfosService.generate({
      title: 'Réinitialiser le mot de passe',
      description: 'Vous avez oublié votre mot de passe ? Pas de panique, nous allons vous aider à le réinitialiser pour accéder à votre compte',
    });
  },

};
</script>
