import { formatGDD, formatTimeSlot, tmsToFormat } from 'potagerlogic/dist/Utils/Dates/DateFormat';
import { getMinimumDeliveryDate } from 'potagerlogic/dist/Utils/Dates/DateCalculation';
import { capitalize } from 'potagerlogic/dist/Formatting';
import WORDINGS from 'potagerlogic/dist/Constants/Wordings';
import { Context, SubscriptionContexts } from 'potagerlogic/dist/Enums/Context';

/**
 * Check if the given GDD (Group Delivery Day) is a replacement date.
 *
 * @param {Object} gdd - The GDD object.
 * @param {Object} gdd.currentWeekTheoricalTimeSlot - The theoretical time slot for the current week.
 * @param {Object} gdd.timeSlot - The actual time slot for the GDD.
 * @param {boolean} gdd.isNextDeliveryAvailable - Indicates if the next delivery is available.
 * @returns {boolean} - True if the GDD is a replacement date, otherwise false.
 */
export const isGddReplacementDate = (gdd) =>{
  return gdd.dayLabel && tmsToFormat(gdd.timeSlot.date, 'EEEE') !== gdd.dayLabel.toLowerCase();
}

/**
 * Check if the given GDD (Group Delivery Day) is replaced on a new GDD.
 *
 *
 * @param {Object} gdd - The GDD object.
 * @param {Object} dlp - The DLP object containing group delivery days.
 * @returns {boolean} - True if the GDD is replaced on a new GDD, otherwise false.
 */
export const isGddReplaceOnANewDate = (gdd, dlp) =>
  isGddReplacementDate(gdd) && !dlp.groupDeliveryDay?.some(day => day.dayLabel.toLowerCase() === tmsToFormat(gdd.timeSlot.date, 'EEEE').toLowerCase());

/**
 * Get the subscription time slot for a given GDD (Group Delivery Day).
 *
 * @param {Object} gdd - The GDD object.
 * @returns {Object} - The subscription time slot for the GDD.
 */
export const getGddSubscriptionTimeSlot = (gdd) => {
  if (gdd.isFull && !gdd.isFullForSubscription && gdd.notFullTimeslot) return gdd.notFullTimeslot;
  return gdd.timeSlot;
};

/**
 * Get the non-subscription time slot for a given GDD (Group Delivery Day).
 *
 * @param {Object} params - The parameters object.
 * @param {Object} params.timeSlot - The time slot object.
 * @param {Object} params.currentWeekTheoricalTimeSlot - The theoretical time slot for the current week.
 * @param {Object} params.currentWeekTimeSlot - The time slot for the current week.
 * @returns {Object} - The appropriate time slot for the GDD.
 */
export const getGddNonSubscriptionTimeSlot = ({
  timeSlot,
  currentWeekTheoricalTimeSlot,
  currentWeekTimeSlot
} = {} ) => {
  const isSameDate = (slot1, slot2) => slot1?.date === slot2?.date;
  if (isSameDate(currentWeekTimeSlot, currentWeekTheoricalTimeSlot)) return currentWeekTimeSlot;
  if (isSameDate(timeSlot, currentWeekTheoricalTimeSlot) || isSameDate(timeSlot, currentWeekTimeSlot)) return timeSlot;
  return currentWeekTheoricalTimeSlot || currentWeekTimeSlot || timeSlot;
};

/**
 * Get the note title for a given delivery point and group delivery day.
 *
 * @param {Object} deliveryPoint - The delivery point object.
 * @param {Object} groupDeliveryDay - The group delivery day object.
 * @param {Object} options - Options object.
 * @param {string} [options.context=Context.Basket] - The context in which the function is called.
 * @returns {string} - The note title for the delivery point and group delivery day.
 */
export const getNoteTitle = (deliveryPoint, groupDeliveryDay, { context = Context.Basket } = {}) => {
  const prefix = deliveryPoint?.isHomeDelivery ? 'Message de notre partenaire' : 'Message du point relais';
  const date = getGddFormattedDay(groupDeliveryDay, { context }) ? `pour le<br></span> ${capitalize(getGddFormattedDay(groupDeliveryDay, context))}` : '';
  return `<span class="font-normal">${prefix} ${date}`;
};

/**
 * Get the note for a given delivery point and group delivery day.
 *
 * @param {Object} deliveryPoint - The delivery point object.
 * @param {Object} groupDeliveryDay - The group delivery day object.
 * @param {Object} options - Options object.
 * @param {string} [options.context=Context.Basket] - The context in which the function is called.
 * @returns {string} - The note for the delivery point and group delivery day.
 */
export const getNote = (deliveryPoint, groupDeliveryDay, { context = Context.Basket } = {}) => {
  const dlpNote = deliveryPoint?.note;
  const gddNote = getGddTimeSlot(groupDeliveryDay, { context })?.note;
  if (dlpNote && gddNote && dlpNote === gddNote) return dlpNote;
  return dlpNote || gddNote ? `${dlpNote || ''}${dlpNote && gddNote ? '<br>' : ''}${gddNote || ''}` : '';
};

/**
 * Get a short note for a given group delivery day.
 *
 * @param {Object} groupDeliveryDay - The group delivery day object.
 * @param {Object} options - Options object.
 * @param {string} [options.context=Context.Basket] - The context in which the function is called.
 * @returns {string|undefined} - A short note if there is a substitute and the date is not a replacement, otherwise undefined.
 */
export const getShortNote = (groupDeliveryDay, { context = Context.Basket } = {}) =>
  getGddTimeSlot(groupDeliveryDay)?.hasSubstitute && !isGddReplacementDate(groupDeliveryDay)
    ? 'Les commandes seront livrées dans un point relais remplaçant ce jour.'
    : undefined;

/**
 * Get the time slot for a given GDD (Group Delivery Day).
 *
 * @param {Object} gdd - The GDD object.
 * @param {Object} options - Options object.
 * @param {string} options.context - The context in which the function is called.
 * @returns {Object} - The time slot for the GDD.
 */
export const getGddTimeSlot = (gdd, { context } = {}) => {
  if (!gdd) return null;
  return SubscriptionContexts.includes(context?.toUpperCase())
    ? gdd.currentWeekTheoricalTimeSlot
    : gdd.timeSlot;
};

/**
 * Get the formatted day for a given GDD (Group Delivery Day).
 *
 * @param {Object} gdd - The GDD object.
 * @param {Object} options - Options object.
 * @param {string} options.context - The context in which the function is called.
 * @param {boolean} [options.condensed=false] - Whether to use condensed formatting.
 * @param {boolean} [options.showHours=false] - Whether to show hours in the formatted output.
 * @returns {string} - The formatted day for the GDD.
 */
export const getGddFormattedDay = (gdd, {
  context,
  condensed = false,
  showHours = false
} = {}) => {
  const timeslot = getGddTimeSlot(gdd, { context });
  const replacement = isGddReplacementDate(gdd) && !condensed ?
    `<br>(en remplacement du ${formatTimeSlot(gdd.currentWeekTheoricalTimeSlot, {
      condensed: true,
      showHours: false
    })})` : '';

  return SubscriptionContexts.includes(context?.toUpperCase())
    ? formatGDD(gdd, { condensed: condensed || !showHours })
    : formatTimeSlot(timeslot, {
    condensed,
    showHours
  }) + replacement;
};

/**
 * Provides a brief message about the minimum delivery date.
 *
 * @param {Object} gdd - The delivery day object.
 * @param {Object} gdd.timeSlot - The time slot for the delivery day.
 * @returns {string} A message indicating the minimum delivery date or an empty string if no delivery day is provided.
 */
export const getGddMinimumDateBrief = (gdd) => {
  if (gdd) {
    const minimumDate = tmsToFormat(getMinimumDeliveryDate(gdd), 'eeee dd MMMM');
    return WORDINGS.MINIMUM_DATE_BRIEF.replace('{minimumDate}', minimumDate);
  }
  return '';
};

/**
 * Convert an address object to a parameter object.
 *
 * @param {Object} address - The address object.
 * @param {string} address.city - The city of the address.
 * @param {string} address.comment - Additional comments for the address.
 * @param {string} address.company - The company name associated with the address.
 * @param {string} address.firstname - The first name of the person associated with the address.
 * @param {string} address.lastname - The last name of the person associated with the address.
 * @param {string} address.note - Additional notes for the address.
 * @param {string} address.phone - The phone number associated with the address.
 * @param {string} address.postalCode - The postal code of the address.
 * @param {string} address.streetName - The street name of the address.
 * @returns {Object|undefined} - The parameter object or undefined if the address is not provided.
 */
export const convertAddressToParameter = (address) => (address ? {
  addressCity: address.city || address.addressCity,
  addressComment: address.comment || address.addressComment,
  addressCompany: address.company || address.addressCompany,
  addressFirstname: address.firstname || address.addressFirstname,
  addressLastname: address.lastname || address.addressLastname,
  addressNote: address.note || address.addressNote,
  phone: address.phone || address.phone,
  addressPostcode: address.postalCode || address.addressPostcode, // addressStreetNumber: address.streetNumber || address.addressStreetNumber,  <--- will be used after BO update to split street and number
  addressStreetName: address.streetName || address.addressStreetName,
} : undefined);
