<template>
  <panel
    :back-route="{ name: 'mon-compte_subscription' }"
    title="Suspendre mon abonnement">
    <div class="panel-section">
      <p class="panel-section__p">
        Vous partez en vacances ou vous avez besoin de faire une pause&nbsp;?
      </p>

      <p class="panel-section__p">
        <b>Vous pouvez sauter une livraison sans pour autant suspendre votre abonnement.</b>
      </p>

      <panel-section-navigator
        v-gtm
        :to="updateSuspensionLink">
        Sauter une livraison
      </panel-section-navigator>

      <panel-section-navigator
        v-gtm
        :to="{ name: 'mon-compte_subscription_suspend_form' }">
        Suspendre sans reprise
      </panel-section-navigator>
    </div>
  </panel>
</template>

<script>
import Panel from 'Components/panel/Panel';
import PanelSectionNavigator from 'Components/panel/section/PanelSectionNavigator.vue';

import MetaInfosService from 'Classes/services/MetaInfoService';

export default {

  components: {
    PanelSectionNavigator,
    Panel,
  },

  computed: {
    updateSuspensionLink() {
      return { name: 'mon-compte_orders' };
    },
  },

  head: MetaInfosService.generate({
    title: 'Suspendre mon abonnement',
  }),
};
</script>
