import { getBoxBlockedOutPrice, getBoxPrice } from 'potagerlogic/dist/Utils/Box/BoxPrices';
import { getBoxSuppliers } from 'potagerlogic/dist/Utils/Box/BoxSuppliers';
import { inAppWebView } from 'Classes/ConfigurationManager';
import { calcSlug } from 'Classes/utils/RouteUtils';

export default function BoxGtmAdapter(store, {
  box,
  parameters = {}
}) {
  if (!box) return null;
  const price = parseFloat(getBoxPrice(box));
  const blockOutPrice = getBoxBlockedOutPrice(box);
  const brand = box.isComplementary ? getBoxSuppliers(box) : undefined;
  const boxSections = store.getters['dashboard/getDashboardSections']
    .filter((section) => section.items.find((item) => item.id === box.id))
    .map((section) => section.title);
  const categories = boxSections
    .map((section, index) => {
      const category = {};
      category[`item_category${index + 2}`] = section;
      return category;
    })
    .reduce((acc, category) => ({ ...acc, ...category }), {});

  return {
    affiliation: inAppWebView() ? 'Application mobile' : 'Site internet',
    discount: blockOutPrice ? price - blockOutPrice : 0,
    item_brand: brand,
    item_category: box.isTypeFormule ? 'Panier en formule d\'abonnement' : 'Panier à la carte',
    item_id: box.id,
    item_list_id: calcSlug(boxSections[0]),
    item_list_name: boxSections[0],
    item_name: box.name,
    price,
    quantity: box.quantity || 1,
    ...categories,
    ...parameters,
  };
}
