import PotagerLink from 'Components/router/PotagerLink';
import { routerResolve } from 'Services/routerServices';

/*
 * Convert our URL
 */
const getQueryParameters = () => {
  const convertQueryStringToObject = (queryString) => {
    if (!queryString) return {};
    queryString = queryString[0] === '?' ? queryString.slice(1) : queryString;
    return queryString
      .split('&')
      .reduce((acc, item) => {
        const [key, value] = item.split('=');
        acc[key] = value;
        return acc;
      }, {});
  };

  const routeQuery = convertQueryStringToObject(document.location.search);
  const potagerRouteQuery = convertQueryStringToObject(document.location.hash.split('?')[1]);
  return { ...routeQuery, ...potagerRouteQuery };
};

export const getPotagerRoute = (route) => {
  if (route?.meta?.isPanel || route?.meta?.isFlying) return route;
  if (!route?.hash) return undefined;

  const hashs = route.hash.split('#');
  const rhash = hashs.length > 1 ? hashs[1] : hashs[0];
  const potagerRoute = routerResolve({ path: rhash });

  if (potagerRoute && hashs.length > 2) {
    potagerRoute.hash = hashs[2];
  }
  potagerRoute.query = route.query || getQueryParameters();

  return potagerRoute || route;
};

// to get the url without the potagerRoute (after #)
export const getPotagerRouteBasePage = (route) => {
  if (!route?.hash) return undefined;
  const hashs = route.hash.split('#');
  const rhash = hashs.length > 1 ? hashs[1] : hashs[0];
  const potagerRoute = routerResolve({ path: rhash });
  return potagerRoute
    ? routerResolve({ path: route.path }).href
    : route;
};

export const isPotagerRoute = (to) => {
  return to.meta?.isPanel || to.meta?.isFlying || to.hash?.includes('#/');
};

export default {
  install(app, {
    router,
    store
  }) {
    const getHomeBase = () => (store.getters['session/isLoggedIn'] ? '/boutique' : '/');

    const resolveBasePotagerRoute = () => {
      const basePotagerRoute = router.resolve({ path: document.location.pathname });

      const { meta } = basePotagerRoute;
      return (meta.isPanel || meta.isFlying || meta.cantHavePanel || !basePotagerRoute) ? getHomeBase() : basePotagerRoute;
    };

    // si la route est un panel/flying, on la convertit en un hash
    // et on garde la route active en route principale
    // afin d'afficher la route ciblée comme une flying/panel via potagerView
    // Ex: potagercity.fr/abonnement/formules-abonnement#/connexion
    // => potagercity.fr/#/connexion
    const resolvePotagerRoute = (_to) => {
      // avoid recursive loop
      if (_to.hash?.includes('#/')) return _to;
      let to = { ...router.resolve(_to) };

      // set name to undefined to avoid the route to be resolved by vue-router
      // so we keep the custom path
      to.name = undefined;
      to.hash = `#${to.path}` + (to.hash ? `#${to.hash}` : '');

      if (!to.query) {
        const routeQuery = app.config.globalProperties.$route?.query;
        to.query = routeQuery || getQueryParameters();
      }

      to.path = resolveBasePotagerRoute()?.href;
      to.fullPath = to.path + to.hash;
      to.href = to.fullPath;
      return to;
    };

    // Define our object $potagerRoute
    Object.defineProperty(app.config.globalProperties, '$potagerRoute', {
      get() {
        const route = router.currentRoute.value;
        return getPotagerRoute(route) || route;
      }
    });

    /*
     * create our component PotagerLink
     */
    app.component('PotagerLink', PotagerLink);

    /*
     * Redirect panels Auth / NoAuth
     */
    router
      .beforeEach((originalTo, from) => {
        let to = { ...originalTo };

        // beforeEnter for potagerRoute
        const potagerBeforeEnter = () => {
          const potagerRoute = getPotagerRoute(to);
          let newTo = undefined;

          potagerRoute?.matched
            .filter((route) => route.beforeEnter)
            .forEach((route) => {
              route.beforeEnter((newTo || potagerRoute), from, (route) => {
                if (typeof route === 'boolean') {
                  if (!route) {
                    newTo = resolveBasePotagerRoute();
                  }
                } else if (route) {
                  newTo = resolvePotagerRoute(route);
                }
              });
            });

          if (newTo && newTo.fullPath !== to.fullPath) {
            console.info('> potagerBeforeEnter', to, '>', newTo);
            return newTo;
          } else {
            return undefined;
          }
        };

        /*
         * redirect if basePage can't have panels
         * Ex: potagercity.fr/abonnement/formules-abonnement#/connexion => potagercity.fr/#/connexion
         */
        const basePageCanHavePanel = () => {
          if (to.meta.cantHavePanel && to.hash) {
            let rhash = to.hash.split('#');
            rhash = rhash.length > 1 ? rhash[1] : rhash[0];
            const route = router.resolve({ path: rhash });

            if (route.meta.isPanel || route.meta.isFlying) {
              const newPath = router.resolve({ name: 'home' });
              newPath.hash = to.hash;

              console.info('> REDIRECT TO BASE PAGE BECAUSE CANT HAVE PANEL');
              return newPath;
            }
          }
        };

        /*
         * Redirect panels
         * Ex: potagercity.fr/login => potagercity.fr/#/connexion
         */
        const redirectPanel = () => {
          if (isPotagerRoute(to) && to !== resolvePotagerRoute(to) && from !== to) {
            console.info('> REDIRECT TO PANEL', to, '>', resolvePotagerRoute(to));
            return resolvePotagerRoute(to);
          }
        };

        /*
         * Prevent an error when we redirect to the same hash
         * In that case we do nothing
         */
        const preventError = () => {
          if ((to.meta.isPanel || to.meta.isFlying) && from.hash) {
            let rhash = from.hash.split('#');
            rhash = rhash.length > 1 ? rhash[1] : rhash[0];

            if (to.path === rhash) {
              console.info('> PREVENT ERROR');
              return false;
            }
          }
        };

        const redirect = potagerBeforeEnter() || redirectPanel() || basePageCanHavePanel() || preventError();
        if (redirect) {
          console.info('> REDIRECT TO', redirect);
          return redirect;
        } else {
          console.info('> NO REDIRECT');
        }
      });

    /*
     * add potagerRoute in meta potagerRoute of route
     */
    router
      .beforeEach((to) => {
        const potagerRoute = getPotagerRoute(to);

        if (potagerRoute) {
          to.meta.potagerRoute = potagerRoute;
        }
      });
  },
};
