<template>
  <panel
    ref="panel"
    :is-loading="!newDeliveryPoint || !newGroupDeliveryDay">
    <div
      v-if="newDeliveryPoint && newGroupDeliveryDay"
      class="panel-section">
      <p class="panel-section__p">
        {{ intro }}
      </p>
      <p
        v-if="notice"
        class="panel-section__p">
        {{ notice }}
      </p>

      <!-- Màj du panier -->
      <collapsible v-if="canUpdateBasket">
        <template #icon>
          <icon-information-circle />
        </template>

        <template #collapsibleHeader>
          <span>
            <potager-switch
              :is-disabled="isLoading"
              :model-value="update.basket"
              disable-label-click
              @update:modelValue="onClick('basket', $event)">
              Panier
            </potager-switch>
          </span>
        </template>

        <template #collapsibleBody>
          <p class="panel-section__p font-bold">
            Livraison actuelle&nbsp;:
          </p>

          <dlp-resume
            :context="context"
            readonly />
        </template>
      </collapsible>

      <!-- Màj de l'abonnement -->
      <collapsible v-if="canUpdateSubscription">
        <template #icon>
          <icon-information-circle />
        </template>
        <template #collapsibleHeader>
          <span>
            <potager-switch
              :is-disabled="isLoading"
              :model-value="update.subscription"
              disable-label-click
              @update:modelValue="onClick('subscription', $event)">
              Abonnement
            </potager-switch>
          </span>
        </template>

        <template #collapsibleBody>
          <p class="panel-section__p">
            En cochant cette case, vous appliquerez le changement
            sur votre abonnement. Les prochaines commandes automatiques
            contenant votre panier en abonnement seront livrées sur
            ce point de retrait.
          </p>
        </template>
      </collapsible>

      <!-- Màj des commandes -->
      <template v-if="canUpdateOrders">
        <collapsible
          v-for="order in getEditableFilteredOrders"
          :key="order.id">
          <template #icon>
            <icon-information-circle />
          </template>

          <template #collapsibleHeader>
            <span>
              <potager-switch
                :is-disabled="isLoading"
                :model-value="isOrderSelected(order.id)"
                disable-label-click
                @update:modelValue="onClickOrder(order.id, $event)">
                Commande en cours
                <span>
                  <br>
                  (n°{{ order.number }})
                </span>
              </potager-switch>
            </span>
          </template>

          <template #collapsibleBody>
            <p class="panel-section__p font-bold">
              Livraison actuelle&nbsp;:
            </p>

            <dlp-resume
              :context="context"
              :order="order"
              readonly />
          </template>
        </collapsible>
      </template>
    </div>

    <template #footer>
      <potager-button
        :is-loading="isLoading"
        :label="btnLabel"
        full-width
        theme="forest"
        @onClick="onValid" />
    </template>
  </panel>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  UPDATE_DELIVERY_POINT_SUBCRIPTION_ACTION,
  UPDATE_DELIVERY_POINT_ORDER_ACTION,
} from 'Stores/types/userActionsTypes';
import { UPDATE_DELIVERY_POINT_BASKET_ACTION } from 'Stores/types/basketActionsTypes';

import IconInformationCircle from 'Icons/regular/IconInformationCircle';

import Panel from 'Components/panel/Panel';
import Collapsible from 'Components/collapsible/Collapsible';
import DlpResume from 'Components/deliveryPoint/DlpResume';

import PotagerButton from 'UI/PotagerButton';
import PotagerSwitch from 'UI/Form/PotagerSwitch';

import { pluralize } from 'potagerlogic/dist/Formatting';
import { convertAddressToParameter } from 'Classes/utils/DlpUtils';
import { getDlpBackRoute } from 'Classes/utils/DlpRouteUtils';

import DlpUpdateMixin from 'Mixins/DlpUpdateMixin';
import { Context } from 'potagerlogic/dist/Enums/Context';

const UPDATE_ALL_PDL_LOADING = 'UPDATE_ALL_PDL';

export default {
  mixins: [DlpUpdateMixin],

  components: {
    DlpResume,
    IconInformationCircle,
    Panel,
    Collapsible,
    PotagerButton,
    PotagerSwitch,
  },

  data: () => ({
    UPDATE_ALL_PDL_LOADING,
    Context,
    newDeliveryPoint: null,
    update: {
      basket: true,
      subscription: false,
      orders: [],
    },
  }),

  props: {
    context: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters('user', [
      'getRegionId',
      'isSubscriptionActive',
      'getEditableOrders',
      'getOrderById',
    ]),
    ...mapGetters('session', [
      'getAddress',
    ]),
    isLoading() {
      return this.$wait.is(UPDATE_ALL_PDL_LOADING);
    },
    address() {
      return this.getAddress(this.context);
    },
    title() {
      const nbOrders = this.getEditableFilteredOrders.length;
      if (this.context === Context.Order) return 'Panier & abonnement';
      if (this.context === Context.Subscription) return `${pluralize(nbOrders, 'commande', null)} en cours`;
      return `Abonnement ${nbOrders ? pluralize(nbOrders, 'commande') : ''}`;
    },
    intro() {
      if (this.context === Context.Order) return 'Le changement de lieu et jour de livraison lié à votre commande a bien été pris en compte.';
      if (this.context === Context.Subscription) return 'Le changement de lieu de livraison lié à votre abonnement a bien été pris en compte pour toutes vos prochaines commandes.';
      return 'Le changement de lieu et jour de livraison lié à votre panier a bien été pris en compte.';
    },
    notice() {
      if (this.context === Context.Order) return 'Souhaitez-vous appliquer ce changement de lieu et jour de livraison à votre panier et votre abonnement ? Si oui, activez le curseur correspondant puis validez';
      if (this.context === Context.Subscription) {
        if (this.getEditableOrders.length > 1) return 'Vous avez actuellement plusieurs commandes en cours. Activez le curseur sur celles de votre choix si vous souhaitez également y appliquer le changement puis validez.';
        return 'Vous avez actuellement une commande en cours. Activez le curseur si vous souhaitez également y appliquer le changement puis validez.';
      }

      let subject = this.isSubscriptionActive ? 'votre abonnement' : '';
      if (this.getEditableOrders.length) {
        subject += this.isSubscriptionActive ? ' et ' : '';
        subject += pluralize(this.getEditableOrders.length, 'votre commande', 'vos commandes');
      }

      return `
        Souhaitez-vous appliquer ce changement de lieu et jour de livraison à ${subject}
        en cours ? Si oui, activez le curseur correspondant puis validez.
      `;
    },
    btnLabel() {
      if (!this.update.basket && !this.update.subscription && !this.update.orders.length) return 'Terminer';
      return 'Valider';
    },
    dlpId() {
      return this.$potagerRoute?.params?.dlpId || this.$route?.params?.dlpId;
    },
    gddId() {
      return parseInt(this.$potagerRoute?.params?.gddId || this.$route?.params?.gddId, 10);
    },
    newGroupDeliveryDay() {
      return this.newDeliveryPoint.groupDeliveryDay.find((gdd) => gdd.id === this.gddId);
    },
  },

  methods: {
    ...mapActions('user', {
      updateUser: 'UPDATE_USER_ACTION',
    }),
    loadDlp() {
      this.$api.deliveryPoints.findOneById(this.getRegionId, this.dlpId)
        .then((response) => {
          this.newDeliveryPoint = response.data;
        });
    },
    onClick(type, value) {
      this.update[type] = value;
    },
    onClickOrder(id, value) {
      const index = this.update.orders.findIndex((o) => o === id);
      if (value && index < 0) {
        this.update.orders.push(id);
      } else if (!value && index >= 0) {
        this.update.orders.splice(index, 1);
      }
    },
    isOrderSelected(id) {
      return this.update.orders.findIndex((o) => o === id) !== -1;
    },
    onValid() {
      const actions = [];

      const { regionId } = this.newDeliveryPoint;
      const { id: groupDeliveryDayId } = this.newGroupDeliveryDay;
      const address = this.newDeliveryPoint?.isHomeDelivery ? convertAddressToParameter(this.address) : null;

      if (this.update.subscription) {
        actions.push({
          name: `user/${UPDATE_DELIVERY_POINT_SUBCRIPTION_ACTION}`,
          payload: {
            regionId,
            groupDeliveryDayId,
            address,
          },
        });
      }

      if (this.update.basket) {
        actions.push({
          name: `basket/${UPDATE_DELIVERY_POINT_BASKET_ACTION}`,
          payload: {
            regionId,
            groupDeliveryDayId,
            address,
          },
        });
      }

      if (this.update.orders.length) {
        this.update.orders.forEach((orderId) => {
          actions.push({
            name: `user/${UPDATE_DELIVERY_POINT_ORDER_ACTION}`,
            payload: {
              orderId,
              regionId,
              groupDeliveryDayId,
              address,
            },
          });
        });
      }

      if (actions.length > 0) {
        this.$wait.start(UPDATE_ALL_PDL_LOADING);

        this.performActions(actions, () => {
          this.$wait.end(UPDATE_ALL_PDL_LOADING);

          this.updateUser();
          this.$notify({
            type: 'success',
            title: 'Changement de lieu de livraison',
            text: 'Votre changement de lieu de livraison a bien été pris en compte.',
          });

          this.$router.push(getDlpBackRoute({
            context: this.context,
            currentRoute: this.$potagerRoute
          }));
        }, () => {
          this.$wait.end(UPDATE_ALL_PDL_LOADING);
        });
      } else {
        this.$router.push(getDlpBackRoute({
          context: this.context,
          currentRoute: this.$potagerRoute
        }));
      }
    },
    performActions(actions, endCallback, errorCallback) {
      const currentAction = actions.shift();

      this.$store.dispatch(
        currentAction.name,
        currentAction.payload,
      )
        .then(() => {
          if (actions.length > 0) {
            this.performActions(actions, endCallback, errorCallback);
          } else {
            endCallback(currentAction);
          }
        })
        .catch((err) => {
          errorCallback(currentAction, err);
        });
    },
  },

  mounted() {
    this.loadDlp();
  },
};
</script>
