<template>
  <p
    ref="container"
    :class="[{ 'line-clamp-2': ellipsis }]">
    <span ref="content">
      <slot />
    </span>
  </p>

  <button
    v-if="needEllipsis"
    class="font-bold underline bg-transparent p-0 border-0"
    @click="ellipsis = !ellipsis">
    {{ ellipsis ? 'Afficher la suite' : 'Masquer' }}
  </button>
</template>

<script>

export default {

  data: () => ({
    ellipsis: true,
    needEllipsis: false,
  }),

  props: {
    modal: {
      type: Boolean,
      required: false,
      default: false,
    },
    modalTitle: {
      type: String,
      required: false,
      default: 'Informations',
    },
  },

  mounted() {
    this.needEllipsis = this.$refs.container.getBoundingClientRect().height < this.$refs.content.getBoundingClientRect().height;
  },
};
</script>
