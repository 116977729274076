<template>
  <template v-if="skeleton">
    <time-slot-card
      v-for="index in skeletonCount"
      :key="`timeslot-skeleton-${index}`"
      :time-slot="index"
      skeleton />
  </template>

  <transition-group
    v-else
    name="slide-left-to-right-2rem">
    <time-slot-card
      v-for="(item, index) in timeSlots"
      :key="`timeslot-${index}`"
      :order="item.timeSlot ? item : null"
      :time-slot="item.timeSlot || item" />
  </transition-group>

  <div
    v-if="showLoadMore && !skeleton"
    class="text-center">
    <potager-button
      :is-loading="isLoading"
      class="mt-4"
      is-rounded
      theme="british-racing"
      @click="$emit('loadMore')">
      Charger plus
    </potager-button>
  </div>
</template>

<script>
import PotagerButton from 'UI/PotagerButton';

import TimeSlotCard from 'Components/timeSlots/TimeSlotCard';

export default {
  name: 'TimeslotsList',

  components: {
    PotagerButton,
    TimeSlotCard
  },

  props: {
    // can pass a list of orders too
    timeSlots: {
      type: Array,
      required: true,
    },
    skeleton: {
      type: Boolean,
      default: false,
    },
    skeletonCount: {
      type: Number,
      default: 5,
    },
    showLoadMore: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['loadMore'],
};
</script>
