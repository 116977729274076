<template>
  <div class="h-full w-full flex flex-col">
    <div
      id="woosmap"
      ref="map"
      class="w-full h-full bg-white-rock" />
  </div>
</template>

<script>
import { toRaw } from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import { UPDATE_WOOS_MAP } from 'Stores/types/mapMutationsTypes';

import SET_WOOS_MAP_LOADER from './DlpMapPublic';

import {
  DEFAULT_MAP_OPTIONS,
  COUNTRY_CENTER,
  DEFAULT_FOCUS_ZOOM_LEVEL,
  getLatLng,
  MARKER_DEFAULT_SELECTED,
  MARKER_DISABLED,
} from 'Classes/utils/MapUtils';
import { isDlpDisabled } from 'Classes/utils/DlpStatusUtils';
import { Context } from 'potagerlogic/dist/Enums/Context';

import WoosMapMixin from 'Mixins/WoosMapMixin';


export default {
  mixins: [WoosMapMixin],

  data: () => ({
    marker: undefined,
  }),

  props: {
    context: {
      type: String,
      required: false,
      default: Context.Basket,
    },
    deliveryPoint: {
      type: Object,
      required: false,
      default: null,
    },
  },

  computed: {
    ...mapGetters('map', [
      'getSelectedDeliveryPoint',
      'getWoosScriptIsLoaded',
      'getWoosMap',
    ]),
    ...mapGetters('session', [
      'getDeliveryPoint',
      'getAddress',
    ]),
    ...mapGetters('homeDelivery', {
      getHomeDeliveryPoint: 'getDeliveryPoint',
      getEditingAddress: 'getAddress',
    }),
    contextualDeliveryPoint() {
      return this.deliveryPoint || this.getSelectedDeliveryPoint || this.getDeliveryPoint(this.context);
    },
    contextualAddress() {
      return this.getEditingAddress || this.getAddress(this.context);
    },
    coordinates() {
      if (this.contextualDeliveryPoint) {
        const address = this.contextualDeliveryPoint.isHomeDelivery ? this.contextualAddress : this.contextualDeliveryPoint;
        return address ? getLatLng(address) : null;
      }
      return null;
    },
    mapCenter() {
      return this.coordinates || this.getUserPosition || COUNTRY_CENTER;
    },
  },

  watch: {
    getWoosScriptIsLoaded: {
      handler(isLoaded) {
        if (isLoaded) {
          this.$nextTick(() => {
            this.setWoosMap();
          });
        }
      },
      immediate: true,
    },
    marker: {
      handler(marker) {
        if (marker) {
          toRaw(marker)
            .setMap(this.getWoosMap);
        }
      },
      immediate: true,
    },
    coordinates: {
      handler(coordinates) {
        if (coordinates && this.marker && this.getWoosMap) {
          toRaw(this.marker)
            .setPosition(coordinates);
          this.getWoosMap.setZoom(DEFAULT_FOCUS_ZOOM_LEVEL);
        }
      },
      immediate: true,
    },
    mapCenter: {
      handler(mapCenter) {
        if (mapCenter && this.getWoosMap) {
          this.getWoosMap.setCenter(mapCenter);
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapMutations('map', [
      UPDATE_WOOS_MAP,
    ]),

    setWoosMap() {
      this.$wait.start(SET_WOOS_MAP_LOADER);
      this.UPDATE_WOOS_MAP(new woosmap.map.Map(this.$refs.map, {
        ...DEFAULT_MAP_OPTIONS,
        gestureHandling: 'none',
      }));

      const loadingListener = woosmap.map.event
        .addListener(this.getWoosMap, 'idle', () => {
          this.$nextTick(() => {
            this.$wait.end(SET_WOOS_MAP_LOADER);

            this.getWoosMap.setCenter(this.mapCenter);
            this.getWoosMap.setZoom(DEFAULT_FOCUS_ZOOM_LEVEL);
            this.marker = new woosmap.map.Marker({
              position: this.coordinates,
              icon: isDlpDisabled(this.contextualDeliveryPoint, { context: this.context }) ? MARKER_DISABLED : MARKER_DEFAULT_SELECTED,
            });

            // on map load, we focus on the selected delivery point
            // this.focusSelectedDeliveryPoint(this.isOnlyGDD, false);
            loadingListener.remove();
          });
        });
    },
  },

  beforeUnmount() {
    if (this.getWoosMap) {
      woosmap.map.event.clearListeners(this.getWoosMap, 'idle');
    }
  },
};
</script>

<style lang="scss">
.slide-bottom-enter-active,
.slide-bottom-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.slide-bottom-enter-from,
.slide-bottom-leave-to {
  opacity: 0;
  transform: translateY(-15%);
}
</style>
