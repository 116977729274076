<template>
  <div
    v-if="hasError"
    class="text-xs.5 bg-rossabel text-cinnabar font-semibold rounded-lg p-3">
    <span class="font-normal">
      ⚠️
    </span>

    <template v-if="isInAnotherRegion">
      Vous ne pouvez pas choisir ce point car il est en dehors de votre région.<br><br>
      <potager-link
        :to="{ name: 'contact' }"
        class="text-current underline"
        target="_blank">
        Contactez le service client<br>
      </potager-link>
      si vous souhaitez changer de région.
    </template>

    <template v-else>
      Désolé, ce point de retrait est actuellement indisponible pour une durée indéterminée.<br>Veuillez
      sélectionner un autre point de retrait parmi ceux disponibles.
    </template>
  </div>

  <div v-else>
    <div
      v-for="(gdd, index) in groupDeliveryDays"
      :key="index"
      class="mb-3 last:mb-0 relative text-left bg-white rounded p-3">
      <div class="flex justify-between">
        <div class="leading-normal font-semibold flex flex-col gap-1">
          <p
            class="text-sm"
            v-html="getGddContent(gdd).day" />

          <potager-tooltip
            v-if="getShortNote(gdd, { context })"
            :message="gdd.timeSlot.note"
            class="font-bold text-zest text-xs"
            position="bottom"
            theme="error">
            <icon-information-circle />
            {{ getShortNote(gdd, { context }) }}
          </potager-tooltip>

          <span
            v-if="getType === DELIVERY_HOME_TYPE"
            class="font-normal text-xs">Livraison <b
              class="font-semibold">entre {{ getGddContent(gdd).from }} et&nbsp;{{ getGddContent(gdd).to }}</b>.
          </span>

          <span
            v-else
            class="font-normal text-xs">Commande déposée <b
              class="font-semibold">avant&nbsp;{{ getGddContent(gdd).from }}</b><br>et disponible <b
              class="font-semibold">jusqu’à {{ getGddContent(gdd).to }}</b>.
          </span>
        </div>

        <potager-button
          :is-disabled="!isEnabled(gdd)"
          :is-loading="isLoading"
          :theme="isEnabled(gdd) ? 'go-green' : 'charcoal'"
          :tooltip="getGddStatus(gdd,{ context })"
          class="self-end font-bold text-xs.5"
          size="small"
          @onClick="onSubmit(gdd)">
          {{ getGddLabel(gdd) }}
        </potager-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  DELIVERY_HOME_TYPE,
  DELIVERY_PRIVATE_TYPE,
  DELIVERY_PUBLIC_TYPE,
} from 'Classes/workflow/TunnelTypesConstants';

import {
  getGddTimeSlot,
  getGddFormattedDay,
  getNote,
  getNoteTitle,
  getShortNote,
  isGddReplacementDate,
  isGddReplaceOnANewDate,
} from 'Classes/utils/DlpUtils';
import { tmsToFormat } from 'potagerlogic/dist/Utils/Dates/DateFormat';

import PotagerButton from 'UI/PotagerButton';
import PotagerTooltip from 'UI/PotagerTooltip';

import ModalList from 'Modals/ModalList';

import { UPDATE_DELIVERY_POINT_LOADERS } from 'Classes/Loaders';
import { capitalize } from 'potagerlogic/dist/Formatting';

import IconInformationCircle from 'Icons/bold/IconInformationCircle';
import { getGddStatus, isGddClosed, isGddDisabled, isGddFull } from 'Classes/utils/DlpStatusUtils';

import { SubscriptionContexts } from 'potagerlogic/dist/Enums/Context';

export default {
  components: {
    PotagerTooltip,
    PotagerButton,
    IconInformationCircle,
  },

  props: {
    context: {
      type: String,
      default: undefined,
    },
    deliveryPoint: {
      type: Object,
      required: true,
    },
  },

  emits: ['onSubmit'],

  data() {
    return {
      DELIVERY_HOME_TYPE,
    };
  },

  computed: {
    ...mapGetters('tunnel', [
      'getPrevStepRoute',
      'getStepRouteByName',
    ]),
    ...mapGetters('map', [
      'getUserSearch',
    ]),
    ...mapGetters('session', [
      'isLoggedIn',
      'getRegionId',
    ]),
    isInAnotherRegion() {
      return this.isLoggedIn && this.deliveryPoint?.regionId !== this.getRegionId;
    },
    groupDeliveryDays() {
      return (this.deliveryPoint?.groupDeliveryDay || [])
        .filter((gdd) => !isGddReplacementDate(gdd) || isGddReplaceOnANewDate(gdd, this.deliveryPoint) || SubscriptionContexts.includes(this.context?.toUpperCase()))
        .sort((a, b) => {
          const aDate = getGddTimeSlot(a, { context: this.context }).date;
          const bDate = getGddTimeSlot(b, { context: this.context }).date;
          return aDate - bDate;
        });
    },
    hasError() {
      return this.isInAnotherRegion || !this.groupDeliveryDays.length;
    },
    getType() {
      if (this.deliveryPoint?.private) {
        return DELIVERY_PRIVATE_TYPE;
      }
      if (this.deliveryPoint?.isHomeDelivery) {
        return DELIVERY_HOME_TYPE;
      }
      return DELIVERY_PUBLIC_TYPE;
    },
    isLoading() {
      return this.$wait.is(UPDATE_DELIVERY_POINT_LOADERS);
    },
  },

  methods: {
    getShortNote,
    getGddStatus,
    getGddLabel(gdd) {
      const { context } = this;
      if (isGddFull(gdd, { context })) return 'Complet';
      if (isGddClosed(gdd, { context }) || isGddDisabled(gdd, { context })) return 'Indisponible';
      return 'Choisir';
    },
    isEnabled(gdd) {
      return !isGddDisabled(gdd, { context: this.context }) && !isGddFull(gdd, { context: this.context });
    },
    getGddContent(gdd) {
      const timeslot = getGddTimeSlot(gdd, { context: this.context });
      const day = capitalize(getGddFormattedDay(gdd, { context: this.context }));

      const from = tmsToFormat(timeslot.startHour, 'HH\'h\'mm');
      const to = tmsToFormat(timeslot.endHour, 'HH\'h\'mm');
      return {
        day,
        from,
        to,
      };
    },
    onSubmit(gdd) {
      const note = getNote(this.deliveryPoint, gdd, { context: this.context });
      if (note) {
        this.$modal.open(ModalList, {
          title: getNoteTitle(this.deliveryPoint, gdd, { context: this.context }),
          items: [
            {
              title: this.deliveryPoint.groupName,
              description: note,
              icon: 'ℹ️',
            },
          ],
          btnLabel: 'C’est noté !',
          btnTheme: 'go-green',
          btnCallback: () => this.$emit('onSubmit', gdd),
        });
      } else {
        this.$emit('onSubmit', gdd);
      }
    },
  },
};
</script>
