<template>
  <collapsible
    :force-open="forceOpen"
    body-class="bg-white-smoke text-xs.5 font-semibold">
    <template #collapsibleHeader>
      <div class="flex justify-between w-full mr-2">
        <slot name="title">
          {{ coupon.couponCode }}
        </slot>

        <template v-if="($slots.value || coupon.couponValue) && !isProductCoupon">
          <span class="text-success font-bold">
            <slot name="value">
              -{{ formatPrice(coupon.couponValue, { unit: coupon.couponValueType === PERCENT ? '%' : '€' }) }}
            </slot>
          </span>
        </template>
      </div>
    </template>

    <template #collapsibleBody>
      <template v-if="coupon.couponValue || $slots.subtitle">
        <p class="font-bold text-xs.5 uppercase mb-2 last:mb-0">
          <slot name="subtitle">
            {{ couponLabel }}
          </slot>
        </p>
      </template>
      <template v-if="coupon.couponEndDate">
        <div
          :class="{
            'mb-0.5 last:mb-0': true,
            'text-warning': isAlmostExpired
          }">
          <p :class="{ 'font-bold': isAlmostExpired }">
            Date d'expiration: {{ tmsToFormat(coupon.couponEndDate, 'dd/MM/yyyy') }}
          </p>
          <p v-if="isAlmostExpired">
            Votre date d'expiration arrive bientôt !<br>
            Pensez à utiliser ce coupon :)
          </p>
        </div>
      </template>
      <template v-if="coupon.couponCode">
        <p class="mb-0.5 last:mb-0">
          <template v-if="coupon.couponNbPerclientUseable">
            Utilisable : {{ coupon.couponNbPerclientUseable }} fois
            <template v-if="coupon.couponUsableOnlyFirstOrder">
              sur la première commande
            </template>
          </template>
          <template v-else>
            Utilisable : en illimité
          </template>
        </p>
      </template>
      <template v-if="couponTypeMsg">
        <p class="mb-0.5 last:mb-0">
          Type : {{ couponTypeMsg }}
        </p>
      </template>
      <template v-if="coupon.couponMaxValue">
        <p class="mb-0.5 last:mb-0">
          Max : {{ formatPrice(coupon.couponMaxValue) }}
        </p>
      </template>
      <template v-if="coupon.comment || $slots.comment">
        <p class="mb-0.5 last:mb-0 mt-2 first:mt-0 italic">
          <slot name="comment">
            {{ coupon.comment }}
          </slot>
        </p>
      </template>

      <template v-if="$slots.actions || canChangeCouponAutomatedConfig">
        <div class="mt-4">
          <slot name="actions">
            <potager-switch
              v-if="canChangeCouponAutomatedConfig"
              :is-loading="isLoading"
              :model-value="coupon.isAutomated"
              size="small"
              @update:modelValue="changeCouponAutomatedValue">
              Appliquer ce coupon automatiquement
            </potager-switch>
          </slot>
        </div>
      </template>
    </template>
  </collapsible>
</template>

<script>
import { mapGetters } from 'vuex';
import { UPDATE_COUPON_AUTOMATED_VALUE_ACTION } from 'Stores/types/userActionsTypes';

import { PERCENT, getCouponTypeMsg, PRODUCT, LUNCHEON } from 'Classes/utils/CouponsUtils';
import { tmsToFormat } from 'potagerlogic/dist/Utils/Dates/DateFormat';
import { formatPrice } from 'potagerlogic/dist/Formatting';

import Collapsible from 'Components/collapsible/Collapsible';

import PotagerSwitch from 'UI/Form/PotagerSwitch';

import { addWeeks, fromUnixTime, isAfter } from 'date-fns';

export default {

  components: {
    Collapsible,
    PotagerSwitch,
  },

  props: {
    coupon: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    forceOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      PERCENT,
    };
  },

  computed: {
    ...mapGetters('user', [
      'getCouponAutomatedConfiguration',
    ]),
    isAlmostExpired() {
      if (this.coupon?.couponEndDate) {
        const expirationDate = fromUnixTime(this.coupon.couponEndDate);
        const inOneWeek = addWeeks(new Date(), 1);
        return isAfter(inOneWeek, expirationDate);
      }
      return false;
    },
    couponTypeMsg() {
      return getCouponTypeMsg(this.coupon);
    },
    canChangeCouponAutomatedConfig() {
      return this.coupon?.id
        && this.coupon?.couponValueType !== PERCENT
        && this.getCouponAutomatedConfiguration.isActivated;
    },
    isLoading() {
      return this.$wait.is(`${UPDATE_COUPON_AUTOMATED_VALUE_ACTION}_${this.coupon?.id}`);
    },
    isProductCoupon() {
      return this.coupon.couponValueType === PRODUCT;
    },
    couponLabel() {
      return this.isProductCoupon
        ? this.coupon.giftedProductName
        : `${formatPrice(this.coupon.couponValue, { unit: this.coupon.couponValueType === PERCENT ? '%' : '€' })} ${this.coupon.couponType !== LUNCHEON ? 'de remise' : 'Titre Restaurant*'}`;
    },
  },

  methods: {
    formatPrice,
    tmsToFormat,
    changeCouponAutomatedValue() {
      if (this.coupon?.id) {
        this.$store.dispatch(`user/${UPDATE_COUPON_AUTOMATED_VALUE_ACTION}`, {
          couponId: this.coupon.id,
          isAutomated: !this.coupon.isAutomated,
        });
      }
    },
  },

};
</script>
