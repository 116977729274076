<template>
  <panel
    :back-route="redirectRoute"
    title="Rien reçu ?">
    <div class="panel-section">
      <p class="panel-section__title">
        Avez-vous regardé dans vos spams ?
      </p>
      <p class="panel-section__p">
        Il se peut que le message soit tombé dans vos spams.
        Pour éviter que cela ne se reproduise, ajoutez Potager City à vos contacts.
      </p>

      <p class="panel-section__title">
        Utilisez vous une adresse e-mail professionnelle&nbsp;?
      </p>
      <p class="panel-section__p">
        Certaines boites professionnelles ne sont pas configurées pour accepter nos emails.
        Nous vous conseillons d'utiliser votre adresse email personnelle.
      </p>

      <potager-form
        id="resend-email-form"
        @onSubmit="resentMail">
        <potager-input
          v-model="email"
          :constraints="[
            { constraint: 'required', field: 'email' },
            { constraint: 'email', field: 'email' },
          ]"
          label="Adresse e-mail"
          name="email" />
        <potager-button
          :is-loading="isLoading"
          full-width
          type="submit">
          Renvoyer
        </potager-button>
      </potager-form>
    </div>
  </panel>
</template>

<script>
import PotagerForm from 'UI/Form/PotagerForm';
import PotagerInput from 'UI/Form/PotagerInput';
import PotagerButton from 'UI/PotagerButton';

import Panel from 'Components/panel/Panel';

import MetaInfosService from 'Classes/services/MetaInfoService';
import { UPDATE_EMAIL_ACTION } from 'Stores/types/userActionsTypes';

export default {

  components: {
    PotagerForm,
    PotagerInput,
    PotagerButton,
    Panel,
  },

  data: () => ({
    email: null,
    redirectRoute: { name: 'mon-compte_confirm-email' },
  }),

  computed: {
    isLoading() {
      return this.$wait.is(UPDATE_EMAIL_ACTION);
    },
  },

  methods: {
    resentMail() {
      const { email } = this;
      this.$store.dispatch(`user/${UPDATE_EMAIL_ACTION}`, { email })
        .then((response) => {
          if (response.data.success) {
            this.$router.push(this.redirectRoute)
              .catch(() => {
              });
          }
        });
    },
  },

  head: MetaInfosService.generate({
    title: 'Rien reçu ?',
  }),
};
</script>
