import { mapGetters } from 'vuex';

import OrderActionMixin from 'Mixins/OrderActionMixin';

import { tmsToFormat } from 'potagerlogic/dist/Utils/Dates/DateFormat';

import { addWeeks, fromUnixTime, isThisWeek } from 'date-fns';
import { isOrderCanceledStatus } from 'potagerlogic/dist/Utils/Order/OrderStatus';

export default {
  mixins: [
    OrderActionMixin,
  ],

  computed: {
    ...mapGetters('user', [
      'getSubscription',
      'getNextDeliveredTimeslot',
      'isSubscriptionActive',
      'getNextGenerationDate',
    ]),
    isNextWeek() {
      if (this.order && !isOrderCanceledStatus(this.order)) {
        const orderDate = fromUnixTime(this.order.paymentDate);
        // Vérifie si la date de paiement est dans la semaine suivant la semaine actuelle
        // On ajoute une semaine à la date actuelle
        // et on vérifie si la date de paiement tombe cette semaine
        return !isThisWeek(orderDate) && isThisWeek(addWeeks(orderDate, -1));
      }
      return false;
    },
    isEditable() {
      return this.order?.isEditable || false;
    },
    isResumable() {
      return this.order?.isResumable || false;
    },
    isNextDeliveryAvailable() {
      return this.order?.groupDeliveryDay?.isNextDeliveryAvailable || false;
    },
    getNextDeliveryDate() {
      let ts = this.getNextDeliveredTimeslot;
      if (this.order?.groupDeliveryDay) {
        ts = this.isNextWeek ? this.order.groupDeliveryDay.timeSlot : this.order.groupDeliveryDay.currentWeekTimeSlot;
      }
      return ts ? tmsToFormat(ts.date, 'iiii dd MMMM') : null;
    },
  },
};
