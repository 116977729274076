import { UPDATE_REQUEST_URL_ACTION } from 'Stores/types/sessionActionsTypes';
import { getPotagerRoute } from 'Plugins/potagerRouting';
import { redirectWebApp } from 'Mixins/MobileAppMixin';
import { storeDispatch, storeGetters } from 'Services/storeServices';

export default (to, from, next) => {
  const route = getPotagerRoute(to) || to;

  if (route.meta.requiresAuth && !storeGetters('session/isLoggedIn')) {
    storeDispatch(`session/${UPDATE_REQUEST_URL_ACTION}`, route);
    console.info('shouldBeConnected > Redirecting to login page');
    redirectWebApp({
      requestUrl: route,
      guard: 'shouldBeConnected'
    });
    next({ name: 'login' });
  }

  next();
}
