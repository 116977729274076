import { mapGetters } from 'vuex';
import {
  REMOVE_BOX_BASKET_ACTION,
  ADD_BOX_BASKET_ACTION,
  EDIT_BOX_QUANTITY_BASKET_ACTION
} from 'Stores/types/basketActionsTypes';

export default {
  computed: {
    ...mapGetters('session', ['isLoggedIn',]),
  },

  methods: {
    addBoxToBasket(box) {
      const idRegion = box.region.id;
      const idBox = box.id;

      return new Promise((resolve, reject) => {
        this.$store.dispatch(`basket/${ADD_BOX_BASKET_ACTION}`, {
          idRegion,
          idBox
        })
          .then((resp) => resolve(resp))
          // most of the time, it means that the gdd is next week
          // so we can't add a box to the basket yet
          // so we prevent from triggering an error
          .catch((err) => {
            console.warn(err);
          });
      });
    },
    removeBoxToBasket(box) {
      const idRegion = box.region.id;
      const idBox = box.id;

      this.$store.dispatch(`basket/${REMOVE_BOX_BASKET_ACTION}`, {
        idRegion,
        idBox,
      });
    },
    editBoxQuantityToBasket(box, quantity) {
      const idRegion = box.region.id;
      const idBox = box.id;

      this.$store.dispatch(`basket/${EDIT_BOX_QUANTITY_BASKET_ACTION}`, {
        idRegion,
        idBox,
        quantity,
      });
    },
  },
};
