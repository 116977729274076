<template>
  <time-slots-list
    :is-loading="$wait.is(LOAD_HISTORICAL_ORDERS)"
    :show-load-more="hasHistoricalOrdersNextPage"
    :skeleton="!isUserLoaded"
    :time-slots="getHistoricalOrders"
    @loadMore="loadHistoricalOrders({ page: 'more' })" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TimeSlotsList from 'Components/timeSlots/TimeSlotsList';
import { LOAD_HISTORICAL_ORDERS } from 'Stores/types/userActionsTypes';

export default {
  name: 'HistoricalOrdersTimeSlotsList',
  components: { TimeSlotsList },
  data() {
    return {
      LOAD_HISTORICAL_ORDERS,
    };
  },
  computed: {
    ...mapGetters('user', [
      'isUserLoaded',
      'getHistoricalOrders',
      'hasHistoricalOrdersNextPage',
    ]),
  },

  methods: {
    ...mapActions('user', {
      loadHistoricalOrders: LOAD_HISTORICAL_ORDERS,
    }),
  },
};
</script>
